import React, { useState } from 'react';
import { Button, TextField } from '@material-ui/core';
import {
  DeleteOutline as DeleteOutlineIcon,
  Edit as EditIcon,
} from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import { DataGrid, Modal } from '../';
import { modalInitialState } from '../../modules/drivers/utils';
import useStyles from './styles';

const MergedDriversTable = (props) => {
  const styles = useStyles(useTheme());

  const { name, rows, columns, options } = props;

  const [mergedColumns, setMergedColumns] = useState(columns);
  const [editMergedData, setEditMergedData] = useState({
    data: {},
    edit: false,
  });
  const [modalData, setModalData] = useState(modalInitialState);

  const onEditMergedRowClick = (evt, row) => {
    evt.stopPropagation();
    setEditMergedData({
      data: row,
      edit: true,
    });

    setMergedColumns((state) => [
      ...state.filter((obj) => obj.key !== 'edit' && obj.key !== 'delete'),
      { key: 'save', name: 'Salveaza', width: 75 },
      { key: 'cancel', name: 'Anuleaza', width: 75 },
    ]);
  };

  const editMergedAction = (key, value) => {
    setEditMergedData({
      edit: true,
      data: {
        ...editMergedData.data,
        [key]: value,
      },
    });
  };

  const onSaveMergedClick = (evt) => {
    evt.stopPropagation();
    setModalData({
      ...modalData,
      open: true,
      title: 'Esti sigur ca doresti sa modifici aceasta linie?',
      text: '',
      onSuccess: () => {
        props.onSaveDataClick(editMergedData.data);
        setModalData(modalInitialState);
        onCancelEditMergedRowClick(evt);
      },
      onSuccessText: 'Salveaza',
      onClose: () => setModalData(modalInitialState),
    });
  };

  const onDeleteMergedRowClick = (evt, row) => {
    evt.stopPropagation();
    setModalData({
      ...modalData,
      open: true,
      title: 'Esti sigur ca doresti sa stergi aceasta linie?',
      text: 'Stergerea va fi permanenta!',
      onSuccess: () => {
        props.onDeleteDataClick(row.id);
        setModalData(modalInitialState);
      },
      onSuccessText: 'Sterge',
      onClose: () => setModalData(modalInitialState),
    });
  };

  const onCancelEditMergedRowClick = (evt) => {
    evt.stopPropagation();
    setEditMergedData({
      data: {},
      edit: false,
    });

    setMergedColumns((state) => [
      ...state.filter((obj) => obj.key !== 'save' && obj.key !== 'cancel'),
      { key: 'edit', name: 'Editeaza', width: 75 },
      { key: 'delete', name: 'Sterge', width: 75 },
    ]);
  };

  const getMergedDataRows = () =>
    rows.map((data) => {
      if (data.id === editMergedData.data.id) {
        return {
          ...data,
          lastName: (
            <TextField
              variant="standard"
              value={editMergedData.data.lastName}
              error={!editMergedData.data.lastName.length}
              onChange={(evt) => editMergedAction('lastName', evt.target.value)}
            />
          ),
          firstName: (
            <TextField
              variant="standard"
              value={editMergedData.data.firstName}
              error={!editMergedData.data.firstName.length}
              onChange={(evt) =>
                editMergedAction('firstName', evt.target.value)
              }
            />
          ),
          save: (
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              disabled={
                !editMergedData.data.firstName || !editMergedData.data.lastName
              }
              onClick={onSaveMergedClick}
            >
              Salveaza
            </Button>
          ),
          cancel: (
            <Button
              variant="contained"
              color="primary"
              size="small"
              type="button"
              onClick={onCancelEditMergedRowClick}
            >
              Anuleaza
            </Button>
          ),
        };
      }

      return {
        ...data,
        edit: (
          <EditIcon
            className={styles.editIcon}
            onClick={(evt) => onEditMergedRowClick(evt, data)}
          />
        ),
        delete: (
          <DeleteOutlineIcon
            className={styles.deleteIcon}
            onClick={(evt) => onDeleteMergedRowClick(evt, data)}
          />
        ),
      };
    });

  return (
    <>
      <DataGrid
        name={name}
        columns={mergedColumns}
        rows={getMergedDataRows()}
        options={options}
      />
      <Modal {...modalData} />
    </>
  );
};

export default React.memo(MergedDriversTable);
