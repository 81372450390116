import { cloneDeep } from 'lodash';
import {
  FETCH_CONFIGS_SUCCESS,
  FETCH_CONFIGS_ERROR,
  ADD_CONFIG,
  EDIT_CONFIG,
  SAVE_CONFIG_SUCCESS,
  SAVE_CONFIG_ERROR,
  SAVE_CONFIG_INVALID,
  DELETE_CONFIG_SUCCESS,
  DELETE_CONFIG_ERROR,
  TEST_CONFIG_CONNECTION_ERROR,
  RESET,
} from '../actions/actionNames';
import {
  serversConfigState,
  locationsConfigState,
  tanksConfigState,
  receiptsConfigState,
  RoutesTypes,
  ConfigTypes,
} from '../utils';

const initialState = {
  configs: {
    nexus: [],
    fleetman: [],
    locations: [],
    tanks: [],
    receipts: [],
  },
  noOfNewConfigs: 0,
  selectOptions: {},
  error: {},
};

const getFormInitialStateByType = (type) => {
  const configStates = {
    nexus: serversConfigState,
    fleetman: serversConfigState,
    locations: locationsConfigState,
    tanks: tanksConfigState,
    receipts: receiptsConfigState,
  };

  return cloneDeep(configStates[type]);
};

const fetchConfigsSuccess = (state, configs, selectOptions) => {
  // add form state data to DB configs
  const configsWithState = {};
  Object.keys(configs).forEach((configTypeKey) => {
    const configType = configs[configTypeKey];
    configsWithState[configTypeKey] = [];

    configType.forEach((config) => {
      let formState = getFormInitialStateByType(configTypeKey);

      Object.keys(config).forEach((fieldKey) => {
        if (fieldKey === 'routes') {
          formState[fieldKey].value = config[fieldKey].map(
            (route) =>
              RoutesTypes.find((_route) => route === _route.label).value
          );
          formState[fieldKey].errors = [];
          return;
        }
        if (formState[fieldKey]) {
          formState[fieldKey].value = config[fieldKey];
          formState[fieldKey].errors = [];
        }
      });

      if (configTypeKey === ConfigTypes.TANKS) {
        formState.locationId.options = configs.locations.map(
          (locationState) => ({
            value: locationState.id,
            label: locationState.managementName,
          })
        );

        formState.productName.options = selectOptions.tankProductNameOptions;
        formState.tankName.options = selectOptions.tankNameOptions;
      }

      configsWithState[configTypeKey].push(formState);
    });
  });

  return {
    ...state,
    configs: configsWithState,
    selectOptions,
  };
};

const addConfig = (state, type) => {
  const { noOfNewConfigs, configs, selectOptions } = state;

  let newConfigState = getFormInitialStateByType(type);

  newConfigState.id.value = `new-config-${noOfNewConfigs + 1}`;
  newConfigState.type.value = type;

  if (type === ConfigTypes.TANKS) {
    newConfigState.locationId.options = configs.locations.map(
      (locationState) => ({
        value: locationState.id.value,
        label: locationState.managementName.value,
      })
    );

    newConfigState.productName.options = selectOptions.tankProductNameOptions;
    newConfigState.tankName.options = selectOptions.tankNameOptions;
  }
  return {
    ...state,
    configs: {
      ...configs,
      [type]: [newConfigState, ...configs[type]],
    },
    noOfNewConfigs: noOfNewConfigs + 1,
  };
};

const editConfig = (state, { type, id, key, value }) => {
  const typeState = cloneDeep(state.configs[type]);
  const toEditIndex = state.configs[type].findIndex(
    (config) => config.id.value === id
  );
  typeState[toEditIndex][key].value = value;
  typeState[toEditIndex][key].edited = true;

  if (typeState[toEditIndex][key].validation) {
    typeState[toEditIndex][key].errors = typeState[toEditIndex][key].validation(
      value
    );
  }

  return {
    ...state,
    configs: {
      ...state.configs,
      [type]: typeState,
    },
  };
};

const saveConfigSuccess = (state, { type, id, data }) => {
  const typeState = cloneDeep(state.configs[type]);
  const index = typeState.findIndex((ts) => ts.id.value === id);
  typeState[index].id.value = data.id;

  Object.keys(typeState[index]).forEach(
    (key) => (typeState[index][key].edited = false)
  );

  return {
    ...state,
    configs: {
      ...state.configs,
      [type]: typeState,
    },
  };
};

const saveConfigInvalid = (state, { type, id, data }) => {
  const typeState = cloneDeep(state.configs[type]);
  const index = typeState.findIndex((ts) => ts.id.value === id);
  typeState[index] = data;

  return {
    ...state,
    configs: {
      ...state.configs,
      [type]: typeState,
    },
  };
};

const deleteConfigSuccess = (state, { type, id }) => {
  const typeState = cloneDeep(state.configs[type]).filter(
    (ts) => ts.id.value !== id
  );
  return {
    ...state,
    configs: {
      ...state.configs,
      [type]: typeState,
    },
  };
};

const configError = (state, error) => ({ ...state, error });

const configsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONFIGS_SUCCESS:
      return fetchConfigsSuccess(
        state,
        action.payload.configs,
        action.payload.selectOptions
      );

    case ADD_CONFIG:
      return addConfig(state, action.payload);
    case EDIT_CONFIG:
      return editConfig(state, action.payload);

    case SAVE_CONFIG_SUCCESS:
      return saveConfigSuccess(state, action.payload);
    case SAVE_CONFIG_INVALID:
      return saveConfigInvalid(state, action.payload);

    case DELETE_CONFIG_SUCCESS:
      return deleteConfigSuccess(state, action.payload);

    case FETCH_CONFIGS_ERROR:
    case SAVE_CONFIG_ERROR:
    case DELETE_CONFIG_ERROR:
    case TEST_CONFIG_CONNECTION_ERROR:
      return configError(state, action.error);

    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default configsReducer;
