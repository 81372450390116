import { POST } from '../../../utils/http';
import { paths } from '../../../utils/config';
import { setIsAuthenticated } from '../../../utils/interceptors';

export const authenticateUser = (userData, history) => async (dispatch) => {
  try {
    const { REACT_APP_PROTOCOL, REACT_APP_BASE_URL } = process.env;
    const URL = `${REACT_APP_PROTOCOL}://${REACT_APP_BASE_URL}`;
    const result = await POST(URL, paths.POST_LOGIN_USER, userData);
    if (result.data?.token.length) {
      localStorage.setItem('fleet-garage.auth_token', result.data.token);
      dispatch(setIsAuthenticated(null, null));
      history.push('/');
    }
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
  }
};
