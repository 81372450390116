import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Grid, Button } from '@material-ui/core';
import { fetchDashboardData, resetDashboardData } from './actions';
import { DataGrid, FormField } from '../../components';
import { receiptStatsColumns, normalizationColumns } from './utils';
import { DATE_FORMAT, editFilterDate } from '../../utils';
import useStyles from './styles';

const Dashboard = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector((state) => state.globalReducer);

  const { receiptsStatsData, normalizationData } = useSelector(
    (state) => state.dashboard
  );

  useEffect(() => {
    dispatch(fetchDashboardData());

    return () => dispatch(resetDashboardData());
  }, [dispatch]);

  return (
    <Container className={styles.navbarDisplayFlex}>
      <Grid container justify="space-between" item xs={12}>
        <Box my={3}>
          <p className={styles.title}>Dashboard</p>
        </Box>
      </Grid>

      <Grid container justify="space-between" alignItems="center" item xs={12}>
        <Box my={5}>
          <p>Statistici cantitati alimentari</p>
        </Box>
        <Grid container justify="space-evenly" alignItems="center" item xs={6}>
          <FormField
            type="receipts"
            id="startDate1"
            _key="startDate"
            data={startDate}
            maxDate={endDate.value}
            onChange={(date) =>
              dispatch(editFilterDate('startDate', date.format(DATE_FORMAT)))
            }
          />
          <FormField
            type="receipts"
            id="endDate1"
            _key="endDate"
            data={endDate}
            minDate={startDate.value}
            onChange={(date) =>
              dispatch(editFilterDate('endDate', date.format(DATE_FORMAT)))
            }
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            disabled={
              startDate.errors.length !== 0 || endDate.errors.length !== 0
            }
            onClick={() => dispatch(fetchDashboardData())}
          >
            Cauta
          </Button>
        </Grid>
      </Grid>

      <Grid>
        <DataGrid
          name="receiptStats"
          columns={receiptStatsColumns}
          rows={receiptsStatsData}
        />
      </Grid>
      <Box my={5}>
        <Grid>
          <p>Normalizari efectuate</p>
          <DataGrid
            name="normalization"
            columns={normalizationColumns}
            rows={normalizationData}
            options={{ filter: true }}
          />
        </Grid>
      </Box>
    </Container>
  );
};

export default Dashboard;
