import { makeStyles } from '@material-ui/core';

export default makeStyles({
  loadingScreenContainer: {
    content: '',
    display: 'block',
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    background: 'rgba(20, 20, 20, 0.6)',
  },

  loader: {
    top: '50%',
    left: '50%',
    position: 'fixed',
    zIndex: '999',
    transform: 'translate(-50%, -50%)',
  },
});
