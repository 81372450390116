import { RESET } from '../actions/actionNames';

const initialState = {};

const normalizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default normalizationReducer;
