import { GET } from '../../../utils/http';
import { getBaseApi, paths } from '../../../utils/config';
import * as types from './actionNames';

export const fetchDashboardData = () => async (dispatch, getState) => {
  try {
    const { startDate, endDate } = getState().globalReducer;
    const dashboardResponse = await GET(
      getBaseApi(),
      `${paths.FETCH_DASHBOARD}`,
      {
        startDate: startDate.value,
        endDate: endDate.value,
      }
    );

    const normalizationResponse = await GET(
      getBaseApi(),
      `${paths.FETCH_NORMALIZATION}`
    );

    dispatch(
      fetchDashboardDataSuccess({
        receiptsStatsData: dashboardResponse.data.receiptsStats,
        normalizationData: normalizationResponse.data.normalization,
      })
    );
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(fetchDashboardDataError(error.response?.data || error.message));
  }
};

export const fetchDashboardDataSuccess = (payload) => ({
  type: types.FETCH_DASHBOARD_DATA_SUCCESS,
  payload,
});
export const fetchDashboardDataError = (error) => ({
  type: types.FETCH_DASHBOARD_DATA_ERROR,
  error,
});

export const resetDashboardData = () => ({
  type: types.RESET,
});
