import moment from 'moment';
import { cloneDeep } from 'lodash';
import { DATE_FORMAT } from '../utils';
import { dateDifferenceDays } from '../utils/validations';

const initialState = {
  loading: false,
  isAuthenticated: null,
  userInfo: {},
  startDate: {
    value: moment(new Date()).subtract(1, 'month').format(DATE_FORMAT),
    label: 'Data Start',
    type: 'date',
    validation: dateDifferenceDays('startDate', 'endDate', 30),
    errors: [],
  },
  endDate: {
    value: moment(new Date()).format(DATE_FORMAT),
    label: 'Data sfarsit',
    type: 'date',
    validation: dateDifferenceDays('endDate', 'startDate', 30),
    errors: [],
  },
};

const globalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GLOBAL_REDUCER::SET_LOADING_SCREEN': {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case 'GLOBAL_REDUCER::SET_IS_AUTHENTICATED': {
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        userInfo: action.payload.userInfo,
      };
    }

    case 'GLOBAL_REDUCER::EDIT_FILTER_DATE': {
      const { key, value } = action.payload;
      const _state = cloneDeep(state);
      const keyState = _state[key];
      keyState.value = value;
      keyState.edited = true;
      if (keyState.validation) {
        keyState.errors = keyState.validation(_state, value);
      }
      return _state;
    }

    default:
      return state;
  }
};

export default globalReducer;
