import {
  FETCH_DASHBOARD_DATA_SUCCESS,
  FETCH_DASHBOARD_DATA_ERROR,
  RESET,
} from '../actions/actionNames';

const initialState = {
  receiptsStatsData: [],
  normalizationData: [],
};

const fetchDashboardDataSuccess = (
  state,
  { receiptsStatsData, normalizationData }
) => {
  return {
    ...state,
    receiptsStatsData: receiptsStatsData.map((d, index) => ({
      ...d,
      id: index,
    })),
    normalizationData: normalizationData.map((d) => ({ ...d, ...d.data })),
  };
};

const dashboardDataError = (state, error) => ({ ...state, error });

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA_SUCCESS:
      return fetchDashboardDataSuccess(state, action.payload);

    case FETCH_DASHBOARD_DATA_ERROR:
      return dashboardDataError(state, action.error);

    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default dashboardReducer;
