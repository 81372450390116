import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import DateFnsUtils from '@date-io/moment';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { DATE_FORMAT } from '../../utils';

const FormField = ({ type, id, _key, data, minDate, maxDate, onChange }) => {
  if (data.hidden) return null;

  switch (data.type) {
    case 'input':
      return (
        <TextField
          variant="standard"
          id={`${type}${_key}${id}`}
          value={data.value}
          label={data.label}
          disabled={data.disabled}
          error={!!data.errors.length}
          helperText={data.errors.length ? data.errors.join('\n') : ' '}
          onChange={onChange}
        />
      );
    case 'select':
      return (
        <TextField
          variant="standard"
          fullWidth={true}
          select
          id={`${type}${_key}${id}`}
          value={data.value}
          label={data.label}
          disabled={data.disabled}
          error={!!data.errors.length}
          helperText={data.errors.length ? data.errors.join('\n') : ' '}
          onChange={onChange}
          SelectProps={{}}
        >
          {data.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );
    case 'autocomplete':
      return (
        <Autocomplete
          id={`${type}${_key}${id}`}
          options={data.options}
          getOptionLabel={(option) => option.label}
          onInputChange={onChange}
          freeSolo
          renderInput={(params) => (
            <TextField
              {...params}
              label={data.label}
              variant="standard"
              fullWidth={true}
              value={data.value}
              disabled={data.disabled}
              error={!!data.errors.length}
              helperText={data.errors.length ? data.errors.join('\n') : ' '}
            />
          )}
        />
      );
    case 'select-multiple':
      return (
        <TextField
          variant="standard"
          fullWidth={true}
          select
          id={`${type}${_key}${id}`}
          label={data.label}
          disabled={data.disabled}
          error={!!data.errors.length}
          helperText={data.errors.length ? data.errors.join('\n') : ' '}
          SelectProps={{
            multiple: true,
            value: data.value,
            onChange,
          }}
        >
          {data.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      );

    case 'date':
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            minDate={minDate || undefined}
            maxDate={maxDate || undefined}
            variant="standard"
            label={data.label}
            error={!!data.errors.length}
            helperText={data.errors.length ? data.errors.join('\n') : ' '}
            value={data.value}
            onChange={onChange}
            format={DATE_FORMAT}
            disableFuture
          />
        </MuiPickersUtilsProvider>
      );
    default:
      return (
        <TextField
          variant="standard"
          id={`${type}${_key}${id}`}
          value={data.value}
          label={data.label}
          disabled={data.disabled}
          error={!!data.errors.length}
          helperText={data.errors.length ? data.errors.join(' ') : ' '}
          onChange={onChange}
        />
      );
  }
};

export default FormField;
