import { makeStyles } from '@material-ui/core';

export default makeStyles({
  tableWrapper: {
    marginBottom: '15px',
    maxHeight: '50vh',
    overflow: 'auto',
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%',
    fontSize: '14px',
  },
  th: {
    border: '1px solid #dddddd',
    padding: '7px 5px',
    textAlign: 'center',
    backgroundColor: 'white',
    position: 'sticky',
    top: '-1px',
  },
  td: {
    border: '1px solid #dddddd',
    padding: '7px 5px',
    textAlign: 'center',
  },
});
