import React, { useEffect, useState } from 'react';
import { Grid, Button, FormControlLabel, Checkbox } from '@material-ui/core';
import { DataGrid } from '..';

// import useStyles from './styles';

const ValidReceiptsTable = (props) => {
  // const styles = useStyles();
  const { rows, columns, checkBoxes } = props;

  const [selectedRows, setSelectedRows] = useState([]);
  const [uniqueReceipt, setUniqueReceipt] = useState([]);
  const [groupReceipts, setGroupReceipts] = useState(false);
  const [groupCheck, setGroupCheck] = useState(false);
  const onValidRowClick = (name, row) => {
    // if the id is in selectedRows, then we filter it out
    // and it is a deselect row action
    const filterSelectedRows = selectedRows.filter((sr) => sr.id !== row.id);

    let _selectedRows;
    if (filterSelectedRows.length === selectedRows.length - 1) {
      _selectedRows = filterSelectedRows;
    } else {
      _selectedRows = [...selectedRows, row];
    }
    setSelectedRows(_selectedRows);
  };

  const onSelectAllClick = (name, rows, checked) => {
    if (checked) {
      setSelectedRows([...selectedRows, ...rows]);
    } else {
      const rowsIds = rows.map((r) => r.id);
      const _selectedRows = selectedRows.filter(
        (sr) => rowsIds.indexOf(sr.id) === -1
      );
      setSelectedRows(_selectedRows);
    }
  };

  const onPushNexusReceiptClick = () => {
    props.postPushNexusReceipt(
      selectedRows.map((sr) => sr.id),
      groupReceipts,
      uniqueReceipt,
      groupCheck
    );
    setSelectedRows([]);
  };

  const groupSelectedRows = () => {
    setGroupCheck(!groupCheck);
    if (!groupCheck && selectedRows.length > 0) {
      let uniqueSelectedRows = [
        ...selectedRows.map(({ den_primitor, nume_transport, tip_produs }) => {
          return { den_primitor, nume_transport, tip_produs };
        }),
      ];
      uniqueSelectedRows = uniqueSelectedRows.filter(
        (uniqueRows, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.den_primitor === uniqueRows.den_primitor &&
              t.nume_transport === uniqueRows.nume_transport &&
              t.tip_produs === uniqueRows.tip_produs
          )
      );
      setUniqueReceipt({ uniqueSelectedRows, selectedRows });
    }
  };

  useEffect(() => {
    checkBoxes > 0 && setGroupCheck(false);
  }, [checkBoxes]);

  return (
    <>
      <Grid container justify="space-between" alignItems="center">
        <p>Alimentari valide</p>
        <Grid>
          <FormControlLabel
            control={
              <Checkbox
                checked={groupCheck}
                onChange={() => {
                  setGroupReceipts(!groupReceipts);
                  groupSelectedRows();
                }}
                name="checkedB"
                color="primary"
              />
            }
            label="Grupeaza alimentarile"
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            disabled={!selectedRows.length}
            onClick={onPushNexusReceiptClick}
          >
            {`Trimite Alimentari ${
              selectedRows.length ? `(${selectedRows.length})` : ''
            }`}
          </Button>
        </Grid>
      </Grid>
      <DataGrid
        name="valid"
        columns={columns}
        rows={rows}
        selectedRows={selectedRows}
        options={{
          onRowClick: onValidRowClick,
          filter: true,
          selectAll: true,
          onSelectAllClick,
        }}
        checkBoxes={checkBoxes}
      />
    </>
  );
};

export default ValidReceiptsTable;
