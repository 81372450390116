import axios from 'axios';
import store from '../store';
import { toastError } from '../utils';

const _axios = axios.create();
const { dispatch } = store;

_axios.interceptors.request.use(
  (config) => {
    dispatch(setLoadingScreen(true));
    return config;
  },
  (error) => Promise.reject(error)
);

_axios.interceptors.response.use(
  (response) => {
    dispatch(setLoadingScreen(false));
    return response;
  },
  (error) => {
    dispatch(setLoadingScreen(false));
    let toastMessage = 'Something went wrong! Please try again!';
    toastMessage += error?.response?.data
      ? JSON.stringify(error?.response?.data)
      : '';
    toastMessage += error?.response?.message
      ? JSON.stringify(error?.response?.message)
      : '';
    toastMessage += error.message ? `. ${error.message}` : '';

    toastError(toastMessage);

    if ([401, 403].indexOf(error.response?.status) !== -1) {
      localStorage.removeItem('fleet-garage.auth_token');
      dispatch(setIsAuthenticated(false, null));
    }
    return Promise.reject(error);
  }
);

export const setIsAuthenticated = (isAuthenticated, userInfo) => ({
  type: 'GLOBAL_REDUCER::SET_IS_AUTHENTICATED',
  payload: { isAuthenticated, userInfo },
});

export const setLoadingScreen = (loading) => ({
  type: 'GLOBAL_REDUCER::SET_LOADING_SCREEN',
  payload: loading,
});

export default _axios;
