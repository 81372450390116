import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Button,
  Box,
  Grid,
  AppBar,
  Tab,
  Tabs,
  Divider,
} from '@material-ui/core';
import { FormField, Modal } from '../../components';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import {
  fetchConfigs,
  addConfig,
  editConfig,
  deleteConfig,
  saveConfig,
  testConfigConnection,
  resetConfigs,
} from './actions';
import { ConfigTypes, modalInitialState } from './utils';
import useStyles from './styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Box>
  );
};

const Configs = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { configs } = useSelector((state) => state.configs);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [modalData, setModalData] = useState(modalInitialState);

  useEffect(() => {
    dispatch(fetchConfigs());

    return () => dispatch(resetConfigs());
  }, [dispatch]);

  const onSaveClick = (type, config) => {
    setModalData({
      ...modalData,
      open: true,
      title:
        'Configurarea a fost modificata. Esti sigur ca doresti sa salvezi?',
      text: '',
      onSuccess: () => {
        dispatch(saveConfig(ConfigTypes[type], config.id.value));
        setModalData(modalInitialState);
      },
      onSuccessText: 'Salveaza',
      onClose: () => setModalData(modalInitialState),
    });
  };

  const onDeleteClick = (type, config) => {
    setModalData({
      ...modalData,
      open: true,
      title: 'Esti sigur ca doresti sa stergi configurarea?',
      text: 'Stergerea va fi permanenta!',
      onSuccess: () => {
        dispatch(deleteConfig(ConfigTypes[type], config.id.value));
        setModalData(modalInitialState);
      },
      onSuccessText: 'Sterge',
      onClose: () => setModalData(modalInitialState),
    });
  };

  return (
    <Container className={styles.navbarDisplayFlex}>
      <Grid container justify="space-between" item xs={12}>
        <Box my={3}>
          <p className={styles.title}>Configurare</p>
        </Box>

        <Box my={3}>
          <Grid container>
            <Box mr={3}>
              <Button
                variant="contained"
                color="primary"
                size="medium"
                type="button"
                onClick={() => props.history.goBack()}
              >
                Inapoi
              </Button>
            </Box>
          </Grid>
        </Box>
      </Grid>

      <Box className={styles.tabsContainer}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTabIndex}
            onChange={(evt, value) => setSelectedTabIndex(value)}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Vertical tabs example"
          >
            {Object.keys(ConfigTypes).map((type, index) => (
              <Tab
                key={type}
                label={type}
                id={`tab-${index}`}
                aria-controls={`tabpanel-${index}`}
              />
            ))}
          </Tabs>
        </AppBar>

        {Object.keys(ConfigTypes).map((type, index) => (
          <TabPanel key={type} value={selectedTabIndex} index={index}>
            {configs[ConfigTypes[type]].length === 0 ||
            ConfigTypes[type] === ConfigTypes.TANKS || ConfigTypes[type] === ConfigTypes.LOCATIONS ? (
              <Grid item xs={12} container justify="flex-end">
                <Box mr={5}>
                  <Button
                    variant="contained"
                    color="default"
                    size="medium"
                    type="button"
                    onClick={() => dispatch(addConfig(ConfigTypes[type]))}
                  >
                    Adauga Config
                  </Button>
                </Box>
              </Grid>
            ) : (
              ''
            )}
            {configs[ConfigTypes[type]].map((config) => (
              <Box key={config.id.value} my={4}>
                <Grid container spacing={3} alignItems="center">
                  {Object.keys(config).map((key) => {
                    if (config[key].hidden) return '';
                    return (
                      <Grid
                        key={`${ConfigTypes[type]}${key}${config.id.value}`}
                        item
                        xs={12}
                        sm={4}
                        md={2}
                      >
                        <FormField
                          type={ConfigTypes[type]}
                          id={config.id.value}
                          _key={key}
                          data={config[key]}
                          onChange={(evt) =>
                            dispatch(
                              editConfig(
                                ConfigTypes[type],
                                config.id.value,
                                key,
                                evt.target.value
                              )
                            )
                          }
                        />
                      </Grid>
                    );
                  })}

                  <Grid
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    container
                    justify="space-evenly"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      type="button"
                      disabled={
                        Object.keys(config).some(
                          (key) => config[key].errors.length
                        ) ||
                        !Object.keys(config).some((key) => config[key].edited)
                      }
                      onClick={() => onSaveClick(type, config)}
                    >
                      Salveaza
                    </Button>
                    {[ConfigTypes.NEXUS, ConfigTypes.FLEETMAN].indexOf(
                      ConfigTypes[type]
                    ) !== -1 ? (
                      <Button
                        variant="contained"
                        color="default"
                        size="small"
                        type="button"
                        onClick={() =>
                          dispatch(
                            testConfigConnection(
                              ConfigTypes[type],
                              config.id.value
                            )
                          )
                        }
                      >
                        Test Conexiune
                      </Button>
                    ) : (
                      ''
                    )}
                  </Grid>
                  <Grid item xs={12} sm={4} md={1}>
                    <DeleteOutlineIcon
                      className={styles.deleteIcon}
                      onClick={() => onDeleteClick(type, config)}
                    />
                  </Grid>
                </Grid>
                <Divider variant="fullWidth" />
              </Box>
            ))}
          </TabPanel>
        ))}
      </Box>

      <Modal {...modalData} />
    </Container>
  );
};

export default Configs;
