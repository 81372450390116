import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, TextField, Box, Grid } from '@material-ui/core';
import { authenticateUser } from './actions';
import useStyles from './styles';

const Login = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { isAuthenticated } = useSelector((state) => state.globalReducer);

  const onSubmitClick = (evt) => {
    evt.preventDefault();
    if (username && password) {
      dispatch(authenticateUser({ username, password }, props.history));
    }
  };

  if (isAuthenticated) {
    props.history.push('/');
    return null;
  }

  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        noValidate
        autoComplete="off"
        onSubmit={onSubmitClick}
      >
        <Grid
          className={styles.grid}
          container
          alignItems="center"
          justify="center"
          direction="column"
        >
          <h1 className={styles.title}> LOGIN </h1>
          <Box m={2}>
            <TextField
              id="username"
              label="Email"
              value={username}
              onChange={(evt) => setUsername(evt.target.value)}
            />
          </Box>
          <Box m={2}>
            <TextField
              id="password"
              label="Password"
              type="password"
              value={password}
              onChange={(evt) => setPassword(evt.target.value)}
            />
          </Box>
          <Box m={2}>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              className={''}
              disabled={!username || !password}
            >
              Login
            </Button>
          </Box>
        </Grid>
      </form>
    </div>
  );
};
export default Login;
