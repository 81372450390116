import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
  Login,
  Configs,
  Dashboard,
  Drivers,
  Vehicles,
  Receipts,
  Normalization,
} from '../modules';
import { AuthHOC, LoadingScreen, Navigation } from '../components';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AppRoute = () => (
  <BrowserRouter>
    <Navigation />
    <Switch>
      <Route exact path="/login" component={Login} />

      <Route exact path="/" component={(props) => AuthHOC(Dashboard, props)} />

      <Route
        exact
        path="/configs"
        component={(props) => AuthHOC(Configs, props)}
      />

      <Route
        exact
        path="/drivers"
        component={(props) => AuthHOC(Drivers, props)}
      />

      <Route
        exact
        path="/vehicles"
        component={(props) => AuthHOC(Vehicles, props)}
      />

      <Route
        exact
        path="/receipts"
        component={(props) => AuthHOC(Receipts, props)}
      />

      <Route
        exact
        path="/normalization"
        component={(props) => AuthHOC(Normalization, props)}
      />
    </Switch>
    <ToastContainer />
    <LoadingScreen />
  </BrowserRouter>
);

export default AppRoute;
