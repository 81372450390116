import React from 'react';
import { Button, Box, Grid, Modal as MaterialUIModal } from '@material-ui/core';
import useStyles from './styles';

const Modal = (props) => {
  const styles = useStyles();

  const { open, title, text, onCloseText, onSuccessText } = props;

  return (
    <MaterialUIModal
      open={open}
      onClose={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Box className={styles.modal}>
        <Box my={3}>
          <h3>{title}</h3>
          <p>{text}</p>
        </Box>
        <Grid item md={12} container justify="space-evenly">
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            type="button"
            onClick={props.onClose}
          >
            {onCloseText}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            onClick={props.onSuccess}
          >
            {onSuccessText}
          </Button>
        </Grid>
      </Box>
    </MaterialUIModal>
  );
};

export default Modal;
