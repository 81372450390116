import { makeStyles } from '@material-ui/core';

const styles = makeStyles({
  navbarDisplayFlex: {
    height: '100vh',
  },
  title: {
    fontSize: '25px',
  },
  tables: {
    height: '100%',
  },
});

export default styles;
