import { makeStyles } from '@material-ui/core';

export default makeStyles({
  container: {
    height: '100vh',
    backgroundColor: '#3F51B5',
  },
  form: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  grid: {
    backgroundColor: '#ffffff',
    width: '350px',
    padding: '20px',
  },
  title: {
    fontSize: '30px',
  },
});
