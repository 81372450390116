import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Container,
  Grid,
} from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { setIsAuthenticated } from '../../utils/interceptors';
import useStyles from './styles';

const navLinks = [
  { title: 'Dashboard', path: '/' },
  { title: 'Alimentari si Bonuri', path: '/receipts' },
  { title: 'Vehicule', path: '/vehicles' },
  { title: 'Soferi', path: '/drivers' },
  { title: 'Normalizari', path: '/normalization' },
  { title: 'Configurare', path: '/configs' },
];

const Navigation = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.globalReducer);

  const onLogoutClick = () => {
    localStorage.removeItem('fleet-garage.auth_token');
    dispatch(setIsAuthenticated(false, null));
  };

  if (!isAuthenticated) return null;

  return (
    <AppBar position="static">
      <Toolbar>
        <Container>
          <Grid container justify="space-between">
            <Grid container item xs={10}>
              <Link to="/" className={styles.linkText}>
                <IconButton edge="start" color="inherit" aria-label="home">
                  <Home fontSize="large" />
                </IconButton>
              </Link>
              <List
                component="nav"
                aria-labelledby="main navigation"
                className={styles.navDisplayFlex}
              >
                {navLinks.map(({ title, path }) => (
                  <NavLink
                    to={path}
                    key={title}
                    className={styles.linkText}
                    activeClassName={styles.activeClassName}
                    exact={true}
                  >
                    <ListItem button>
                      <ListItemText primary={title} />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </Grid>
            <Grid container item xs={2} className={styles.linkTextLogout}>
              <ListItem button onClick={onLogoutClick}>
                <ListItemText primary={'Logout'} />
              </ListItem>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Navigation;
