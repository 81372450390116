import { cloneDeep } from 'lodash';
import {
  FETCH_VEHICLES_SUCCESS,
  FETCH_VEHICLES_ERROR,
  FETCH_VEHICLE_DETAILS_SUCCESS,
  FETCH_VEHICLE_DETAILS_ERROR,
  RESET_VEHICLE_DETAILS,
  SAVE_MERGED_VEHICLE_SUCCESS,
  SAVE_MERGED_VEHICLE_ERROR,
  SAVE_NORMALIZED_VEHICLE_SUCCESS,
  SAVE_NORMALIZED_VEHICLE_ERROR,
  DELETE_MERGED_VEHICLE_SUCCESS,
  DELETE_MERGED_VEHICLE_ERROR,
  RESET,
} from '../actions/actionNames';

const initialState = {
  vehicles: {
    nexus: [],
    fleetman: [],
    tanks: [],
    merged: [],
  },
  vehicleDetails: {
    nexus: {},
    fleetman: {},
    tanks: {},
  },
  error: {},
};

const fetchVehiclesSuccess = (state, vehicles) => {
  return {
    ...state,
    vehicles,
  };
};

const fetchVehicleDetailsSuccess = (state, type, data) => {
  return {
    ...state,
    vehicleDetails: {
      ...state.vehicleDetails,
      [type]: data,
    },
  };
};

const resetVehicleDetails = (state) => {
  return {
    ...state,
    vehicleDetails: {
      nexus: {},
      fleetman: {},
      tanks: {},
    },
  };
};

const saveMergedVehicleSuccess = (state, id, vehicle) => {
  const _mergedVehicles = cloneDeep(state.vehicles.merged);
  const _vehicleIndex = state.vehicles.merged.findIndex((d) => d.id === id);
  _mergedVehicles[_vehicleIndex] = vehicle;

  return {
    ...state,
    vehicles: {
      ...state.vehicles,
      merged: _mergedVehicles,
    },
  };
};

const saveNormalizedVehicleSuccess = (state, vehicle) => {
  const _vehicles = cloneDeep(state.vehicles);

  const tanksIndex = _vehicles.tanks.findIndex((n) => n.id === vehicle.tanksId);
  _vehicles.tanks[tanksIndex].mergeId = vehicle.id;

  const nexusIndex = _vehicles.nexus.findIndex((n) => n.id === vehicle.nexusId);
  _vehicles.nexus[nexusIndex].mergeId = vehicle.id;

  if (vehicle.fleetmanId) {
    const fleetmanIndex = _vehicles.fleetman.findIndex(
      (n) => n.id === vehicle.fleetmanId
    );
    _vehicles.fleetman[fleetmanIndex].mergeId = vehicle.id;
  }

  _vehicles.merged = [..._vehicles.merged, vehicle];

  return {
    ...state,
    vehicles: _vehicles,
  };
};

const deleteMergedVehicleSuccess = (state, id) => {
  const _vehicles = cloneDeep(state.vehicles);

  // delete mergeId from all types bc we just deleted the merged combination
  _vehicles.nexus.forEach((vehicle) => {
    if (vehicle.mergeId === id) {
      vehicle.mergeId = null;
    }
  });
  _vehicles.fleetman.forEach((vehicle) => {
    if (vehicle.mergeId === id) {
      vehicle.mergeId = null;
    }
  });
  _vehicles.tanks.forEach((vehicle) => {
    if (vehicle.mergeId === id) {
      vehicle.mergeId = null;
    }
  });
  _vehicles.merged = _vehicles.merged.filter((d) => d.id !== id);

  return {
    ...state,
    vehicles: _vehicles,
  };
};

const vehiclesError = (state, error) => ({ ...state, error });

const vehiclesReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_VEHICLES_SUCCESS:
      return fetchVehiclesSuccess(state, action.payload);
    case FETCH_VEHICLE_DETAILS_SUCCESS:
      return fetchVehicleDetailsSuccess(
        state,
        action.payload.type,
        action.payload.data
      );
    case RESET_VEHICLE_DETAILS:
      return resetVehicleDetails(state, action.payload);

    case SAVE_MERGED_VEHICLE_SUCCESS:
      return saveMergedVehicleSuccess(
        state,
        action.payload.id,
        action.payload.data
      );
    case SAVE_NORMALIZED_VEHICLE_SUCCESS:
      return saveNormalizedVehicleSuccess(state, action.payload);

    case DELETE_MERGED_VEHICLE_SUCCESS:
      return deleteMergedVehicleSuccess(state, action.payload);

    case FETCH_VEHICLES_ERROR:
    case DELETE_MERGED_VEHICLE_ERROR:
    case SAVE_MERGED_VEHICLE_ERROR:
    case FETCH_VEHICLE_DETAILS_ERROR:
    case SAVE_NORMALIZED_VEHICLE_ERROR:
      return vehiclesError(state, action.error);
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default vehiclesReducer;
