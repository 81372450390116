export const FETCH_CONFIGS_SUCCESS = 'CONFIGS::FETCH_CONFIGS_SUCCESS';
export const FETCH_CONFIGS_ERROR = 'CONFIGS::FETCH_CONFIGS_ERROR';

export const ADD_CONFIG = 'CONFIGS::ADD_CONFIG';
export const EDIT_CONFIG = 'CONFIGS::EDIT_CONFIG';

export const SAVE_CONFIG_SUCCESS = 'CONFIGS::SAVE_CONFIG_SUCCESS';
export const SAVE_CONFIG_ERROR = 'CONFIGS::SAVE_CONFIG_ERROR';
export const SAVE_CONFIG_INVALID = 'CONFIGS::SAVE_CONFIG_INVALID';

export const DELETE_CONFIG_SUCCESS = 'CONFIGS::DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_ERROR = 'CONFIGS::DELETE_CONFIG_ERROR';

export const TEST_CONFIG_CONNECTION_ERROR =
  'CONFIGS::TEST_CONFIG_CONNECTION_ERROR';

export const RESET = 'CONFIGS::RESET';
