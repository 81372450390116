import { GET, POST } from '../../../utils/http';
import { getBaseApi, paths } from '../../../utils/config';
import * as types from './actionNames';

export const fetchReceipts = () => async (dispatch, getState) => {
  try {
    const { startDate, endDate } = getState().globalReducer;
    const response = await GET(getBaseApi(), `${paths.FETCH_RECEIPTS}`, {
      startDate: startDate.value,
      endDate: endDate.value,
    });
    dispatch(fetchReceiptsSuccess(response.data));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(fetchReceiptsError(error.response?.data || error.message));
  }
};

export const fetchReceiptsSuccess = (payload) => ({
  type: types.FETCH_RECEIPTS_SUCCESS,
  payload,
});
export const fetchReceiptsError = (error) => ({
  type: types.FETCH_RECEIPTS_ERROR,
  error,
});

export const postPushNexusReceipt =
  (ids, groupReceipts) => async (dispatch) => {
    try {
      const response = await POST(
        getBaseApi(),
        `${paths.POST_PUSH_NEXUS_RECEIPTS}`,
        {
          ids,
          groupReceipts,
        }
      );
      dispatch(postPushNexusReceiptSuccess(response.data));
    } catch (error) {
      //eslint-disable-next-line
      console.error(error);
      dispatch(
        postPushNexusReceiptError(error.response?.data || error.message)
      );
    }
  };

export const postPushNexusReceiptSuccess = (payload) => ({
  type: types.POST_PUSH_NEXUS_RECEIPT_SUCCESS,
  payload,
});
export const postPushNexusReceiptError = (error) => ({
  type: types.POST_PUSH_NEXUS_RECEIPT_ERROR,
  error,
});

export const resetReceipts = () => ({
  type: types.RESET,
});
