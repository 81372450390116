import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Button, Box, Grid, Divider } from '@material-ui/core';
import { FormField, Modal } from '../../components';
import { postNormalization, resetNormalization } from './actions';
import { modalInitialState, normalizationModalMessages } from './utils';
import useStyles from './styles';
import { DATE_FORMAT, editFilterDate } from '../../utils';

const Normalization = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { startDate, endDate } = useSelector((state) => state.globalReducer);

  const [modalData, setModalData] = useState(modalInitialState);

  useEffect(() => {
    return () => dispatch(resetNormalization());
  }, [dispatch]);

  const onNormalizationClick = (type) => {
    setModalData({
      ...modalData,
      open: true,
      title: normalizationModalMessages(startDate.value, endDate.value)[type],
      text: '',
      onSuccess: () => {
        dispatch(postNormalization(type));
        setModalData(modalInitialState);
      },
      onSuccessText: 'Da',
      onClose: () => setModalData(modalInitialState),
    });
  };

  return (
    <Container className={styles.navbarDisplayFlex}>
      <Grid container justify="space-between" item xs={12}>
        <Box my={3}>
          <p className={styles.title}>Normalizari</p>
        </Box>
      </Grid>

      <Box mt={3}>
        <p>Normalizare soferi</p>
        <Box my={3}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            onClick={() => onNormalizationClick('drivers')}
          >
            Normalizare soferi
          </Button>
        </Box>
        <Divider />
      </Box>

      <Box mt={3}>
        <p>Normalizare vehicule</p>
        <Box my={3}>
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            onClick={() => onNormalizationClick('vehicles')}
          >
            Normalizare vehicule
          </Button>
        </Box>
        <Divider />
      </Box>

      <Box mt={3}>
        <p>Normalizare alimentari</p>
        <Box my={3}>
          <Grid
            container
            justify="space-around"
            alignItems="center"
            item
            xs={8}
          >
            <FormField
              type="receipts"
              id="startDate1"
              _key="startDate"
              data={startDate}
              maxDate={endDate.value}
              onChange={(date) =>
                dispatch(editFilterDate('startDate', date.format(DATE_FORMAT)))
              }
            />
            <FormField
              type="receipts"
              id="endDate1"
              _key="endDate"
              data={endDate}
              minDate={startDate.value}
              onChange={(date) =>
                dispatch(editFilterDate('endDate', date.format(DATE_FORMAT)))
              }
            />
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="button"
              onClick={() => onNormalizationClick('receipts')}
              disabled={
                (startDate.value?.length && !endDate.value?.length) ||
                (!startDate.value?.length && endDate.value?.length)
              }
            >
              Normalizare alimentari
            </Button>
          </Grid>
        </Box>
        <Divider />
      </Box>

      <Modal {...modalData} />
    </Container>
  );
};

export default Normalization;
