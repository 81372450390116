import React from 'react';
import { Box, Modal as MaterialUIModal, Divider } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import useStyles from './styles';

const DetailsModal = (props) => {
  const styles = useStyles();

  const { open, details } = props;

  const getModalContent = () => {
    if (Array.isArray(details)) {
      return details.map((detail, index) => (
        <Box key={`${detail}${index}`}>
          <Box my={1}>
            <p>{detail}</p>
          </Box>
          <Divider variant="fullWidth" />
        </Box>
      ));
    }
    if (typeof details === 'object') {
      return Object.keys(details).map((key, index) => {
        if (
          typeof details[key] !== 'string' &&
          typeof details[key] !== 'number'
        ) {
          return '';
        }
        return (
          <p key={`${key}${index}`}>
            {key}: {details[key]}
          </p>
        );
      });
    }

    return details;
  };

  return (
    <MaterialUIModal
      open={open}
      onClose={props.onClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disableBackdropClick
    >
      <Box className={styles.modal}>
        <CloseIcon className={styles.closeIcon} onClick={props.onClose} />
        <Box my={3}>{getModalContent()}</Box>
      </Box>
    </MaterialUIModal>
  );
};

export default DetailsModal;
