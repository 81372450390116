import { cloneDeep } from 'lodash';
import { GET, POST, PUT, DELETE } from '../../../utils/http';
import { getBaseApi, paths } from '../../../utils/config';
import * as types from './actionNames';
import { RoutesTypes } from '../utils';
import {
  toastSuccess,
  toastError,
  capitalizeFirstLetter,
} from '../../../utils';

export const fetchConfigs = () => async (dispatch) => {
  try {
    const response = await GET(getBaseApi(), `${paths.FETCH_CONFIGS}`);
    dispatch(
      fetchConfigsSuccess(response.data.configs, response.data.selectOptions)
    );
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(fetchConfigsError(error.response?.data || error.message));
  }
};

export const fetchConfigsSuccess = (configs, selectOptions) => ({
  type: types.FETCH_CONFIGS_SUCCESS,
  payload: { configs, selectOptions },
});

export const fetchConfigsError = (error) => ({
  type: types.FETCH_CONFIGS_ERROR,
  error,
});

export const editConfig = (type, id, key, value) => ({
  type: types.EDIT_CONFIG,
  payload: { type, id, key, value },
});
export const addConfig = (type) => ({
  type: types.ADD_CONFIG,
  payload: type,
});

const getSubmitPayload = (data, method) => {
  const payload = {};
  Object.keys(data).forEach(
    (key) =>
      (payload[key] =
        typeof data[key].value === 'string'
          ? data[key].value.trim()
          : data[key].value)
  );

  if (payload.routes) {
    payload.routes = payload.routes.map(
      (route) => RoutesTypes.find((_route) => route === _route.value).label
    );
  }

  if (method === 'post') delete payload.id;
  return payload;
};

const validateData = (data) => {
  Object.keys(data).forEach((key) => {
    const obj = data[key];
    if (obj.validation) {
      obj.errors = obj.validation(obj.value);
    }
  });

  return !Object.keys(data).some((keys) => data[keys].errors.length);
};

export const saveConfig = (type, id) => async (dispatch, getState) => {
  try {
    const state = getState();

    const data = cloneDeep(
      state.configs.configs[type].find((config) => config.id.value === id)
    );

    const valid = validateData(data);
    if (!valid) {
      dispatch(saveConfigInvalid(type, id, data));
      return;
    }

    let XHR_METHOD = { fn: POST, value: 'post' };
    if (id.indexOf('new-config') === -1) {
      XHR_METHOD = { fn: PUT, value: 'PUT' };
    }

    const response = await XHR_METHOD.fn(
      getBaseApi(),
      paths.FETCH_CONFIGS,
      getSubmitPayload(data, XHR_METHOD.value)
    );

    toastSuccess('Configurarea a fost salvata cu succes!');
    dispatch(saveConfigSuccess(type, id, response.data));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(saveConfigError(error.response?.data || error.message));
  }
};

export const saveConfigSuccess = (type, id, data) => ({
  type: types.SAVE_CONFIG_SUCCESS,
  payload: { type, id, data },
});
export const saveConfigError = (error) => ({
  type: types.SAVE_CONFIG_ERROR,
  error,
});
export const saveConfigInvalid = (type, id, data) => ({
  type: types.SAVE_CONFIG_INVALID,
  payload: { type, id, data },
});

export const deleteConfig = (type, id) => async (dispatch) => {
  try {
    if (id.indexOf('new-config') !== -1) {
      dispatch(deleteConfigSuccess(type, id));
      return;
    }

    const response = await DELETE(getBaseApi(), paths.FETCH_CONFIGS, {
      type,
      id,
    });

    toastSuccess('Configurarea a fost stearsa cu succes!');
    dispatch(deleteConfigSuccess(type, id, response.data));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(deleteConfigError(error.response?.data || error.message));
  }
};

export const deleteConfigSuccess = (type, id) => ({
  type: types.DELETE_CONFIG_SUCCESS,
  payload: { type, id },
});
export const deleteConfigError = (error) => ({
  type: types.DELETE_CONFIG_ERROR,
  error,
});

export const testConfigConnection = (type, id) => async (
  dispatch,
  getState
) => {
  try {
    const state = getState();
    const data = cloneDeep(
      state.configs.configs[type].find((config) => config.id.value === id)
    );

    const response = await POST(
      getBaseApi(),
      paths.POST_TEST_CONFIG_CONNECTION,
      getSubmitPayload(data)
    );

    const { success, error } = response.data;
    if (success) {
      toastSuccess(
        `Conexiunea ${capitalizeFirstLetter(type)} a fost realizata cu succes!`
      );
    } else if (success === false) {
      toastError(
        `Conexiunea ${capitalizeFirstLetter(
          type
        )} nu a putut fi realizata! ${error}`
      );
    }
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(testConfigConnectionError(error.response?.data || error.message));
  }
};
export const testConfigConnectionError = (error) => ({
  type: types.TEST_CONFIG_CONNECTION_ERROR,
  error,
});

export const resetConfigs = () => ({
  type: types.RESET,
});
