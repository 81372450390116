import { POST } from '../../../utils/http';
import { getBaseApi, paths } from '../../../utils/config';
import * as types from './actionNames';
import { toastSuccess } from '../../../utils';

const getSubmitPayload = (type, state) => {
  let body = {
    type,
  };
  if (type === 'receipts') {
    body = {
      ...body,
      startDate: state.startDate.value,
      endDate: state.endDate.value,
    };
  }
  return body;
};

export const postNormalization = (type) => async (dispatch, getState) => {
  try {
    const state = getState().globalReducer;

    await POST(
      getBaseApi(),
      paths.FETCH_NORMALIZATION,
      getSubmitPayload(type, state)
    );

    toastSuccess('Normalizarea a fost realizata cu succes!');
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(postNormalizationError(error.response?.data || error.message));
  }
};

export const postNormalizationSuccess = (payload) => ({
  type: types.POST_NORMALIZATION_SUCCESS,
  payload,
});

export const postNormalizationError = (error) => ({
  type: types.POST_NORMALIZATION_ERROR,
  error,
});

export const resetNormalization = () => ({
  type: types.RESET,
});
