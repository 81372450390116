import { makeStyles } from '@material-ui/core';

export default makeStyles({
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkText: {
    textDecoration: 'none',
    color: 'white',
    fontSize: '20px',
  },
  linkTextLogout: {
    textDecoration: 'none',
    color: '#FF851B',
  },
  activeClassName: {
    color: '#FF851B',
  },
});
