import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Grid, Button } from '@material-ui/core';

import { MergedDriversTable, UnmergedDriversTable } from '../../components';
import {
  fetchDrivers,
  saveMergedDriver,
  saveNormalizedDriver,
  deleteMergedDriver,
  fetchDriverDetails,
  resetDriverDetails,
  resetDrivers,
} from './actions';
import { mergedColumns, unmergedColumns } from './utils';
import useStyles from './styles';

const Drivers = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { drivers, driverDetails } = useSelector((state) => state.drivers);

  const [selectedRows, setSelectedRows] = useState({
    nexus: null,
    fleetman: null,
    tanks: null,
  });

  useEffect(() => {
    dispatch(fetchDrivers());

    return () => dispatch(resetDrivers());
  }, [dispatch]);

  const onUnmergedRowClick = (name, row) => {
    const { view, ...rowWithoutView } = row;

    if (row.mergeId) return;

    const _selectedRow =
      selectedRows[name]?.id === rowWithoutView.id ? null : rowWithoutView;
    setSelectedRows((state) => ({
      ...state,
      [name]: _selectedRow,
    }));
  };

  const onFetchDriverDetailsClick = (type, id, fullName) => {
    dispatch(fetchDriverDetails(type, id, fullName));
  };

  const onCloseFetchDriverDetailsClick = () => dispatch(resetDriverDetails());

  const addDriverNormalization = () => {
    const driver = {
      fleetmanId: selectedRows.fleetman?.id || null,
      nexusId: selectedRows.nexus.id,
      tanksId: selectedRows.tanks.id,
      markNumber: selectedRows.nexus.markNumber,
      firstName: selectedRows.nexus.firstName,
      lastName: selectedRows.nexus.lastName,
      fullName: selectedRows.nexus.fullName,
    };
    dispatch(saveNormalizedDriver(driver));
    setSelectedRows({
      nexus: null,
      fleetman: null,
      tanks: null,
    });
  };

  return (
    <Container className={styles.navbarDisplayFlex}>
      <Grid container justify="space-between" item xs={12}>
        <Box my={3}>
          <p className={styles.title}>Soferi</p>
        </Box>
      </Grid>

      <Grid container spacing={5} className={styles.tables}>
        <Grid item md={12}>
          <p>Soferi normalizati</p>
          <MergedDriversTable
            name="merged"
            rows={drivers.merged}
            columns={mergedColumns}
            onSaveDataClick={(driver) => dispatch(saveMergedDriver(driver))}
            onDeleteDataClick={(id) => dispatch(deleteMergedDriver(id))}
            options={{ filter: true }}
          />
        </Grid>

        <Grid container justify="flex-end">
          <Box mt={4} mr={3}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="button"
              disabled={!selectedRows.tanks || !selectedRows.nexus}
              onClick={addDriverNormalization}
            >
              Adauga normalizare
            </Button>
          </Box>
        </Grid>

        <Grid item md={4}>
          <p>Soferi Pompe</p>
          <UnmergedDriversTable
            name="tanks"
            columns={unmergedColumns}
            rows={drivers.tanks}
            selectedRows={[selectedRows.tanks]}
            options={{
              onRowClick: onUnmergedRowClick,
              filter: true,
              filterValue: props.location?.state?.filterValue || '',
            }}
            dataDetails={driverDetails.tanks}
            fetchDataDetails={onFetchDriverDetailsClick}
            closeDataDetailsModal={onCloseFetchDriverDetailsClick}
          />
        </Grid>
        <Grid item md={4}>
          <p>Soferi Nexus</p>
          <UnmergedDriversTable
            name="nexus"
            columns={unmergedColumns}
            rows={drivers.nexus}
            selectedRows={[selectedRows.nexus]}
            options={{
              onRowClick: onUnmergedRowClick,
              filter: true,
              filterValue: props.location?.state?.filterValue || '',
            }}
            dataDetails={driverDetails.nexus}
            fetchDataDetails={onFetchDriverDetailsClick}
            closeDataDetailsModal={onCloseFetchDriverDetailsClick}
          />
        </Grid>
        <Grid item md={4}>
          <p>Soferi Fleetman</p>
          <UnmergedDriversTable
            name="fleetman"
            columns={unmergedColumns}
            rows={drivers.fleetman}
            selectedRows={[selectedRows.fleetman]}
            options={{
              onRowClick: onUnmergedRowClick,
              filter: true,
              filterValue: props.location?.state?.filterValue || '',
            }}
            dataDetails={driverDetails.fleetman}
            fetchDataDetails={onFetchDriverDetailsClick}
            closeDataDetailsModal={onCloseFetchDriverDetailsClick}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Drivers;
