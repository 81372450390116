import { makeStyles } from '@material-ui/core';

export default makeStyles({
  modal: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    padding: '20px',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    textAlign: 'center',
    outline: 'none',
  },
});
