import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Grid, Button } from '@material-ui/core';

import { MergedVehiclesTable, UnmergedVehiclesTable } from '../../components';
import {
  fetchVehicles,
  saveMergedVehicle,
  saveNormalizedVehicle,
  deleteMergedVehicle,
  fetchVehicleDetails,
  resetVehicleDetails,
  resetVehicles,
} from './actions';
import { mergedColumns, unmergedColumns } from './utils';
import useStyles from './styles';

const Vehicles = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { vehicles, vehicleDetails } = useSelector((state) => state.vehicles);

  const [selectedRows, setSelectedRows] = useState({
    nexus: null,
    fleetman: null,
    tanks: null,
  });

  useEffect(() => {
    dispatch(fetchVehicles());

    return () => dispatch(resetVehicles());
  }, [dispatch]);

  const onUnmergedRowClick = (name, row) => {
    const { view, ...rowWithoutView } = row;

    if (row.mergeId) return;

    const _selectedRow =
      selectedRows[name]?.id === rowWithoutView.id ? null : rowWithoutView;
    setSelectedRows((state) => ({
      ...state,
      [name]: _selectedRow,
    }));
  };

  const onFetchVehicleDetailsClick = (type, id) => {
    dispatch(fetchVehicleDetails(type, id));
  };

  const onCloseFetchVehicleDetailsClick = (type, id, fullName) => {
    dispatch(resetVehicleDetails());
  };

  const addVehicleNormalization = () => {
    const vehicle = {
      fleetmanId: selectedRows.fleetman?.id || null,
      nexusId: selectedRows.nexus.id,
      tanksId: selectedRows.tanks.id,
      vehicleName: selectedRows.nexus.vehicleName,
      vehiclePlate: selectedRows.nexus.vehiclePlate,
      _vehicleName: selectedRows.nexus._vehicleName,
      _vehiclePlate: selectedRows.nexus._vehiclePlate,
      vehicleLocationID: selectedRows.nexus.vehicleLocationID,
    };
    dispatch(saveNormalizedVehicle(vehicle));
    setSelectedRows({
      nexus: null,
      fleetman: null,
      tanks: null,
    });
  };

  return (
    <Container className={styles.navbarDisplayFlex}>
      <Grid container justify="space-between" item xs={12}>
        <Box my={3}>
          <p className={styles.title}>Vehicule</p>
        </Box>
      </Grid>

      <Grid container spacing={5} className={styles.tables}>
        <Grid item md={12}>
          <p>Vehicule normalizate</p>
          <MergedVehiclesTable
            name="merged"
            rows={vehicles.merged}
            columns={mergedColumns}
            onSaveDataClick={(vehicle) => dispatch(saveMergedVehicle(vehicle))}
            onDeleteDataClick={(id) => dispatch(deleteMergedVehicle(id))}
            options={{ filter: true }}
          />
        </Grid>

        <Grid container justify="flex-end">
          <Box mt={4} mr={3}>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              type="button"
              disabled={!selectedRows.tanks || !selectedRows.nexus}
              onClick={addVehicleNormalization}
            >
              Adauga normalizare
            </Button>
          </Box>
        </Grid>

        <Grid item md={4}>
          <p>Vehicule Pompe</p>
          <UnmergedVehiclesTable
            name="tanks"
            columns={unmergedColumns}
            rows={vehicles.tanks}
            selectedRows={[selectedRows.tanks]}
            options={{
              onRowClick: onUnmergedRowClick,
              filter: true,
              filterValue: props.location?.state?.filterValue || '',
            }}
            dataDetails={vehicleDetails.tanks}
            fetchDataDetails={onFetchVehicleDetailsClick}
            closeDataDetailsModal={onCloseFetchVehicleDetailsClick}
          />
        </Grid>
        <Grid item md={4}>
          <p>Vehicule Nexus</p>
          <UnmergedVehiclesTable
            name="nexus"
            columns={unmergedColumns}
            rows={vehicles.nexus}
            selectedRows={[selectedRows.nexus]}
            options={{
              onRowClick: onUnmergedRowClick,
              filter: true,
              filterValue: props.location?.state?.filterValue || '',
            }}
            dataDetails={vehicleDetails.nexus}
            fetchDataDetails={onFetchVehicleDetailsClick}
            closeDataDetailsModal={onCloseFetchVehicleDetailsClick}
          />
        </Grid>
        <Grid item md={4}>
          <p>Vehicule Fleetman</p>
          <UnmergedVehiclesTable
            name="fleetman"
            columns={unmergedColumns}
            rows={vehicles.fleetman}
            selectedRows={[selectedRows.fleetman]}
            options={{
              onRowClick: onUnmergedRowClick,
              filter: true,
              filterValue: props.location?.state?.filterValue || '',
            }}
            dataDetails={vehicleDetails.fleetman}
            fetchDataDetails={onFetchVehicleDetailsClick}
            closeDataDetailsModal={onCloseFetchVehicleDetailsClick}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Vehicles;
