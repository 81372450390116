import axios from './interceptors';

const _headers = {
  Authorization: 'Bearer ',
  'Content-Type': 'application/json',
};

export const GET = (url, path, params, headers = { ..._headers }) => {
  headers['Authorization'] += localStorage.getItem('fleet-garage.auth_token');
  return axios({
    method: 'GET',
    url: `${url}${path}`,
    headers,
    params,
  });
};

export const POST = (url, path, data, headers = { ..._headers }) => {
  headers['Authorization'] += localStorage.getItem('fleet-garage.auth_token');
  return axios({
    method: 'POST',
    url: `${url}${path}`,
    data,
    headers,
  });
};

export const PUT = (url, path, data, headers = { ..._headers }) => {
  headers['Authorization'] += localStorage.getItem('fleet-garage.auth_token');
  return axios({
    method: 'PUT',
    url: `${url}${path}`,
    data,
    headers,
  });
};

export const DELETE = (url, path, data, headers = { ..._headers }) => {
  headers['Authorization'] += localStorage.getItem('fleet-garage.auth_token');
  return axios({
    method: 'DELETE',
    url: `${url}${path}`,
    data,
    headers,
  });
};
