import { GET, POST, PUT, DELETE } from '../../../utils/http';
import { getBaseApi, paths } from '../../../utils/config';
import * as types from './actionNames';
import { toastSuccess, toastError } from '../../../utils';
import { Visibility as VisibilityIcon } from '@material-ui/icons';

const getVisibilityIcon = (name, data, dispatch) => {
  const onViewIconClick = (evt, type, row) => {
    evt.stopPropagation();
    dispatch(
      fetchDriverDetails(type, row.id, `${row.lastName} ${row.firstName}`)
    );
  };

  return (
    <VisibilityIcon
      className="viewIcon"
      onClick={(evt) => onViewIconClick(evt, name, data)}
    />
  );
};

export const fetchDrivers = () => async (dispatch) => {
  try {
    const response = await GET(getBaseApi(), `${paths.FETCH_DRIVERS}`);
    const { drivers } = response.data;
    const _drivers = {
      ...drivers,
      tanks: drivers.tanks.map((driver) => ({
        ...driver,
        view: getVisibilityIcon('tanks', driver, dispatch),
      })),
      nexus: drivers.nexus.map((driver) => ({
        ...driver,
        view: getVisibilityIcon('nexus', driver, dispatch),
      })),
      fleetman: drivers.fleetman.map((driver) => ({
        ...driver,
        view: getVisibilityIcon('fleetman', driver, dispatch),
      })),
    };

    dispatch(fetchDriversSuccess(_drivers));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(fetchDriversError(error.response?.data || error.message));
  }
};

export const fetchDriversSuccess = (drivers) => ({
  type: types.FETCH_DRIVERS_SUCCESS,
  payload: drivers,
});

export const fetchDriversError = (error) => ({
  type: types.FETCH_DRIVERS_ERROR,
  error,
});

export const saveMergedDriver = (driver) => async (dispatch) => {
  try {
    const response = await PUT(getBaseApi(), paths.FETCH_DRIVERS, driver);
    toastSuccess('Soferul a fost salvat cu succes!');
    dispatch(saveMergedDriverSuccess(driver.id, response.data));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(saveMergedDriverError(error.response?.data || error.message));
  }
};

export const saveMergedDriverSuccess = (id, data) => ({
  type: types.SAVE_MERGED_DRIVER_SUCCESS,
  payload: { id, data },
});
export const saveMergedDriverError = (error) => ({
  type: types.SAVE_MERGED_DRIVER_ERROR,
  error,
});

export const deleteMergedDriver = (id) => async (dispatch) => {
  try {
    const response = await DELETE(getBaseApi(), paths.FETCH_DRIVERS, {
      id,
    });

    if (response.data.success) {
      toastSuccess('Linia a fost stearsa cu succes!');
    } else {
      toastError('Linia nu a putut fi stearsa!');
    }

    dispatch(deleteMergedDriverSuccess(id));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(deleteMergedDriverError(error.response?.data || error.message));
  }
};

export const deleteMergedDriverSuccess = (id) => ({
  type: types.DELETE_MERGED_DRIVER_SUCCESS,
  payload: id,
});
export const deleteMergedDriverError = (error) => ({
  type: types.DELETE_MERGED_DRIVER_ERROR,
  error,
});

export const resetDrivers = () => ({
  type: types.RESET,
});

export const fetchDriverDetails = (type, id, fullName) => async (dispatch) => {
  try {
    const response = await GET(getBaseApi(), `${paths.FETCH_DRIVER_DETAILS}`, {
      type,
      id,
      fullName,
    });

    if (response.data) {
      dispatch(fetchDriverDetailsSuccess(type, response.data));
    } else {
      toastError('Nu am putut gasi detalii despre acest sofer');
    }
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(fetchDriverDetailsError(error.response?.data || error.message));
  }
};

export const fetchDriverDetailsSuccess = (type, data) => ({
  type: types.FETCH_DRIVER_DETAILS_SUCCESS,
  payload: { type, data },
});

export const fetchDriverDetailsError = (error) => ({
  type: types.FETCH_DRIVER_DETAILS_ERROR,
  error,
});

export const resetDriverDetails = () => ({
  type: types.RESET_DRIVER_DETAILS,
});

export const saveNormalizedDriver = (driver) => async (dispatch) => {
  try {
    const response = await POST(getBaseApi(), paths.FETCH_DRIVERS, driver);
    toastSuccess('Soferul a fost salvat cu succes!');
    dispatch(saveNormalizedDriverSuccess(response.data));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(saveNormalizedDriverError(error.response?.data || error.message));
  }
};

export const saveNormalizedDriverSuccess = (payload) => ({
  type: types.SAVE_NORMALIZED_DRIVER_SUCCESS,
  payload,
});
export const saveNormalizedDriverError = (error) => ({
  type: types.SAVE_NORMALIZED_DRIVER_ERROR,
  error,
});
