import { toast } from 'react-toastify';

export const toastSuccess = (toastMessage) => {
  toast.success(toastMessage, {
    position: 'bottom-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const toastError = (toastMessage) => {
  toast.error(toastMessage, {
    position: 'bottom-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_HOURS_FORMAT = 'YYYY-MM-DD HH:mm';

export const editFilterDate = (key, value) => ({
  type: 'GLOBAL_REDUCER::EDIT_FILTER_DATE',
  payload: { key, value },
});

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);
