import { cloneDeep } from 'lodash';
import {
  FETCH_DRIVERS_SUCCESS,
  FETCH_DRIVERS_ERROR,
  FETCH_DRIVER_DETAILS_SUCCESS,
  FETCH_DRIVER_DETAILS_ERROR,
  RESET_DRIVER_DETAILS,
  SAVE_MERGED_DRIVER_SUCCESS,
  SAVE_MERGED_DRIVER_ERROR,
  SAVE_NORMALIZED_DRIVER_SUCCESS,
  SAVE_NORMALIZED_DRIVER_ERROR,
  DELETE_MERGED_DRIVER_SUCCESS,
  DELETE_MERGED_DRIVER_ERROR,
  RESET,
} from '../actions/actionNames';

const initialState = {
  drivers: {
    nexus: [],
    fleetman: [],
    tanks: [],
    merged: [],
  },
  driverDetails: {
    nexus: {},
    fleetman: {},
    tanks: {},
  },
  error: {},
};

const fetchDriversSuccess = (state, drivers) => {
  return {
    ...state,
    drivers,
  };
};

const fetchDriverDetailsSuccess = (state, type, data) => {
  return {
    ...state,
    driverDetails: {
      ...state.driverDetails,
      [type]: data,
    },
  };
};

const resetDriverDetails = (state) => {
  return {
    ...state,
    driverDetails: {
      nexus: {},
      fleetman: {},
      tanks: {},
    },
  };
};

const saveMergedDriverSuccess = (state, id, driver) => {
  const _mergedDrivers = cloneDeep(state.drivers.merged);
  const _driverIndex = state.drivers.merged.findIndex((d) => d.id === id);
  _mergedDrivers[_driverIndex] = driver;

  return {
    ...state,
    drivers: {
      ...state.drivers,
      merged: _mergedDrivers,
    },
  };
};

const saveNormalizedDriverSuccess = (state, driver) => {
  const _drivers = cloneDeep(state.drivers);

  const tanksIndex = _drivers.tanks.findIndex((n) => n.id === driver.tanksId);
  _drivers.tanks[tanksIndex].mergeId = driver.id;

  const nexusIndex = _drivers.nexus.findIndex((n) => n.id === driver.nexusId);
  _drivers.nexus[nexusIndex].mergeId = driver.id;

  if (driver.fleetmanId) {
    const fleetmanIndex = _drivers.fleetman.findIndex(
      (n) => n.id === driver.fleetmanId
    );
    _drivers.fleetman[fleetmanIndex].mergeId = driver.id;
  }

  _drivers.merged = [..._drivers.merged, driver];

  return {
    ...state,
    drivers: _drivers,
  };
};

const deleteMergedDriverSuccess = (state, id) => {
  const _drivers = cloneDeep(state.drivers);

  // delete mergeId from all types bc we just deleted the merged combination
  _drivers.nexus.forEach((driver) => {
    if (driver.mergeId === id) {
      driver.mergeId = null;
    }
  });
  _drivers.fleetman.forEach((driver) => {
    if (driver.mergeId === id) {
      driver.mergeId = null;
    }
  });
  _drivers.tanks.forEach((driver) => {
    if (driver.mergeId === id) {
      driver.mergeId = null;
    }
  });
  _drivers.merged = _drivers.merged.filter((d) => d.id !== id);

  return {
    ...state,
    drivers: _drivers,
  };
};

const driversError = (state, error) => ({ ...state, error });

const driversReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DRIVERS_SUCCESS:
      return fetchDriversSuccess(state, action.payload);
    case FETCH_DRIVER_DETAILS_SUCCESS:
      return fetchDriverDetailsSuccess(
        state,
        action.payload.type,
        action.payload.data
      );
    case RESET_DRIVER_DETAILS:
      return resetDriverDetails(state, action.payload);

    case SAVE_MERGED_DRIVER_SUCCESS:
      return saveMergedDriverSuccess(
        state,
        action.payload.id,
        action.payload.data
      );

    case SAVE_NORMALIZED_DRIVER_SUCCESS:
      return saveNormalizedDriverSuccess(state, action.payload);

    case DELETE_MERGED_DRIVER_SUCCESS:
      return deleteMergedDriverSuccess(state, action.payload);

    case FETCH_DRIVERS_ERROR:
    case DELETE_MERGED_DRIVER_ERROR:
    case SAVE_MERGED_DRIVER_ERROR:
    case FETCH_DRIVER_DETAILS_ERROR:
    case SAVE_NORMALIZED_DRIVER_ERROR:
      return driversError(state, action.error);
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default driversReducer;
