import { makeStyles } from '@material-ui/core';

export default makeStyles({
  navbarDisplayFlex: {
    height: '80vh',
  },
  title: {
    fontSize: '25px',
  },
});
