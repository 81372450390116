import { makeStyles } from '@material-ui/core';

export default makeStyles({
  modal: {
    position: 'absolute',
    width: 400,
    height: '80vh',
    overflow: 'auto',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    padding: '20px',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    textAlign: 'left',
    outline: 'none',
  },
  closeIcon: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    cursor: 'pointer',
  },
});
