import { GET, POST, PUT, DELETE } from '../../../utils/http';
import { getBaseApi, paths } from '../../../utils/config';
import * as types from './actionNames';
import { toastSuccess, toastError } from '../../../utils';
import { Visibility as VisibilityIcon } from '@material-ui/icons';

const getVisibilityIcon = (name, data, dispatch) => {
  const onViewIconClick = (evt, type, row) => {
    evt.stopPropagation();
    dispatch(
      fetchVehicleDetails(type, row.id, `${row.lastName} ${row.firstName}`)
    );
  };

  return (
    <VisibilityIcon
      className="viewIcon"
      onClick={(evt) => onViewIconClick(evt, name, data)}
    />
  );
};

export const fetchVehicles = () => async (dispatch) => {
  try {
    const response = await GET(getBaseApi(), `${paths.FETCH_VEHICLES}`);

    const { vehicles } = response.data;
    const _vehicles = {
      ...vehicles,
      tanks: vehicles.tanks.map((vehicle) => ({
        ...vehicle,
        view: getVisibilityIcon('tanks', vehicle, dispatch),
      })),
      nexus: vehicles.nexus.map((vehicle) => ({
        ...vehicle,
        view: getVisibilityIcon('nexus', vehicle, dispatch),
      })),
      fleetman: vehicles.fleetman.map((vehicle) => ({
        ...vehicle,
        view: getVisibilityIcon('fleetman', vehicle, dispatch),
      })),
    };

    dispatch(fetchVehiclesSuccess(_vehicles));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(fetchVehiclesError(error.response?.data || error.message));
  }
};

export const fetchVehiclesSuccess = (vehicles) => ({
  type: types.FETCH_VEHICLES_SUCCESS,
  payload: vehicles,
});

export const fetchVehiclesError = (error) => ({
  type: types.FETCH_VEHICLES_ERROR,
  error,
});

export const saveMergedVehicle = (vehicle) => async (dispatch) => {
  try {
    const response = await PUT(getBaseApi(), paths.FETCH_VEHICLES, vehicle);
    toastSuccess('Vehiculul a fost salvat cu succes!');
    dispatch(saveMergedVehicleSuccess(vehicle.id, response.data));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(saveMergedVehicleError(error.response?.data || error.message));
  }
};

export const saveMergedVehicleSuccess = (id, data) => ({
  type: types.SAVE_MERGED_VEHICLE_SUCCESS,
  payload: { id, data },
});
export const saveMergedVehicleError = (error) => ({
  type: types.SAVE_MERGED_VEHICLE_ERROR,
  error,
});

export const deleteMergedVehicle = (id) => async (dispatch) => {
  try {
    const response = await DELETE(getBaseApi(), paths.FETCH_VEHICLES, {
      id,
    });

    if (response.data.success) {
      toastSuccess('Linia a fost stearsa cu succes!');
    } else {
      toastError('Linia nu a putut fi stearsa!');
    }

    dispatch(deleteMergedVehicleSuccess(id));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(deleteMergedVehicleError(error.response?.data || error.message));
  }
};

export const deleteMergedVehicleSuccess = (id) => ({
  type: types.DELETE_MERGED_VEHICLE_SUCCESS,
  payload: id,
});
export const deleteMergedVehicleError = (error) => ({
  type: types.DELETE_MERGED_VEHICLE_ERROR,
  error,
});

export const resetVehicles = () => ({
  type: types.RESET,
});

export const fetchVehicleDetails = (type, id, fullName) => async (dispatch) => {
  try {
    const response = await GET(getBaseApi(), `${paths.FETCH_VEHICLE_DETAILS}`, {
      type,
      id,
      fullName,
    });

    if (response.data) {
      dispatch(fetchVehicleDetailsSuccess(type, response.data));
    } else {
      toastError('Nu am putut gasi detalii despre acest vehicul');
    }
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(fetchVehicleDetailsError(error.response?.data || error.message));
  }
};

export const fetchVehicleDetailsSuccess = (type, data) => ({
  type: types.FETCH_VEHICLE_DETAILS_SUCCESS,
  payload: { type, data },
});

export const fetchVehicleDetailsError = (error) => ({
  type: types.FETCH_VEHICLE_DETAILS_ERROR,
  error,
});

export const resetVehicleDetails = () => ({
  type: types.RESET_VEHICLE_DETAILS,
});

export const saveNormalizedVehicle = (vehicle) => async (dispatch) => {
  try {
    const response = await POST(getBaseApi(), paths.FETCH_VEHICLES, vehicle);
    toastSuccess('Vehiculul a fost salvat cu succes!');
    dispatch(saveNormalizedVehicleSuccess(response.data));
  } catch (error) {
    //eslint-disable-next-line
    console.error(error);
    dispatch(saveNormalizedVehicleError(error.response?.data || error.message));
  }
};

export const saveNormalizedVehicleSuccess = (payload) => ({
  type: types.SAVE_NORMALIZED_VEHICLE_SUCCESS,
  payload,
});
export const saveNormalizedVehicleError = (error) => ({
  type: types.SAVE_NORMALIZED_VEHICLE_ERROR,
  error,
});
