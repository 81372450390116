import { RECEIPT_STATUSES } from '../utils';
import {
  FETCH_RECEIPTS_SUCCESS,
  FETCH_RECEIPTS_ERROR,
  POST_PUSH_NEXUS_RECEIPT_SUCCESS,
  POST_PUSH_NEXUS_RECEIPT_ERROR,
  RESET,
} from '../actions/actionNames';
import { toastSuccess, toastError } from '../../../utils';

const initialState = {
  receipts: {
    pushed: [],
    valid: [],
    invalid: [],
  },
  pushedNexusReceipts: [],
};

const fetchReceiptSuccess = (state, receipts, pushedNexusReceipts) => {
  return {
    ...state,
    receipts: {
      pushed: receipts.filter(
        (r) => r.receiptStatus === RECEIPT_STATUSES.PUSHED
      ),
      valid: receipts.filter((r) => r.receiptStatus === RECEIPT_STATUSES.VALID),
      invalid: receipts.filter(
        (r) => r.receiptStatus === RECEIPT_STATUSES.INVALID
      ),
    },
    pushedNexusReceipts: pushedNexusReceipts.map((p) => ({
      ...p,
      ids: p.ids.join(', '),
    })),
  };
};

const postPushNexusReceiptSuccess = (
  state,
  { receiptsResponse, nexusReceiptsResponse }
) => {
  let moveToPushed = [];
  let moveToInvalid = [];
  receiptsResponse.forEach((p) => {
    const obj = state.receipts.valid.find((r) => r.id === p.id);
    if (p.isError === true) {
      obj.receiptStatus = RECEIPT_STATUSES.INVALID;
      obj.errors = [p.message];
      moveToInvalid.push(obj);
    } else {
      obj.receiptStatus = RECEIPT_STATUSES.PUSHED;
      moveToPushed.push(obj);
    }
  });

  const pushed = [...moveToPushed, ...state.receipts.pushed];
  const valid = state.receipts.valid.filter(
    (r) => ![...moveToPushed, ...moveToInvalid].some((m) => m.id === r.id)
  );
  const invalid = [...moveToInvalid, ...state.receipts.invalid];

  moveToPushed.length &&
    toastSuccess(
      `${moveToPushed.length} alimentari din ${receiptsResponse.length} au fost adaugate cu succes in Nexus. Au fost create ${nexusReceiptsResponse.length} bonuri de consum! `
    );

  moveToInvalid.length &&
    toastError(
      `${moveToInvalid.length} alimentari din ${receiptsResponse.length} nu au putut fi adaugate in Nexus. Vezi tabelul de bonuri invalide!`
    );

  return {
    ...state,
    receipts: {
      pushed,
      valid,
      invalid,
    },
    pushedNexusReceipts: [
      ...nexusReceiptsResponse.map((p) => ({
        ...p,
        ids: p.ids.join(', '),
      })),
      ...state.pushedNexusReceipts,
    ],
  };
};

const receiptsError = (state, error) => ({ ...state, error });

const receiptsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECEIPTS_SUCCESS:
      return fetchReceiptSuccess(
        state,
        action.payload.receipts,
        action.payload.pushedNexusReceipts
      );

    case POST_PUSH_NEXUS_RECEIPT_SUCCESS:
      return postPushNexusReceiptSuccess(state, action.payload);

    case FETCH_RECEIPTS_ERROR:
    case POST_PUSH_NEXUS_RECEIPT_ERROR:
      return receiptsError(state, action.error);
    case RESET:
      return initialState;
    default:
      return state;
  }
};

export default receiptsReducer;
