export const unmergedColumns = [
  { key: 'mergeId', name: 'Id normal.', width: 75 },
  { key: 'markNumber', name: 'Nr. marca', width: 75 },
  { key: 'lastName', name: 'Nume', width: 130 },
  { key: 'firstName', name: 'Prenume', width: 130 },
  { key: 'view', name: 'Detalii', width: 75 },
];

export const mergedColumns = [
  { key: 'id', name: 'Id normal.', width: 75 },
  { key: 'tanksId', name: 'Id Pompe', width: 75 },
  { key: 'nexusId', name: 'Id Nexus', width: 75 },
  { key: 'fleetmanId', name: 'Id Fleetman', width: 75 },
  { key: 'markNumber', name: 'Nr. marca', width: 75 },
  { key: 'lastName', name: 'Nume', width: 130 },
  { key: 'firstName', name: 'Prenume', width: 130 },
  { key: 'edit', name: 'Editeaza', width: 75 },
  { key: 'delete', name: 'Sterge', width: 75 },
];

export const modalInitialState = {
  open: false,
  title: '',
  text: '',
  onSuccess: null,
  onSuccessText: '',
  onClose: null,
  onCloseText: 'Anuleaza',
};
