import {
  requiredString,
  requiredNumber,
  requiredArray,
} from '../../utils/validations';

export const ConfigTypes = Object.freeze({
  NEXUS: 'nexus',
  FLEETMAN: 'fleetman',
  LOCATIONS: 'locations',
  TANKS: 'tanks',
  RECEIPTS: 'receipts',
});

export const RoutesTypes = Object.freeze([
  { value: 1, label: 'drivers' },
  { value: 2, label: 'vehicles/list' },
  { value: 3, label: 'bonuri_consum' },
  { value: 4, label: 'vehicule_utilaje' },
  { value: 5, label: 'contracte_munca' },
]);

export const serversConfigState = {
  id: {
    hidden: true,
    errors: [],
  },
  type: {
    hidden: true,
    errors: [],
  },
  ip: {
    value: '',
    label: 'Adresa IP',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  port: {
    value: null,
    label: 'Port',
    type: 'input',
    validation: requiredNumber,
    errors: [],
    edited: false,
  },
  apiKey: {
    value: '',
    label: 'API KEY / Token',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  routes: {
    value: [],
    label: 'Servicii',
    type: 'select-multiple',
    options: RoutesTypes,
    validation: requiredArray,
    errors: [],
    edited: false,
  },
};

export const locationsConfigState = {
  id: {
    hidden: true,
    errors: [],
  },
  type: {
    hidden: true,
    errors: [],
  },
  managementId: {
    value: '',
    label: 'ID gest. Nexus',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  managementName: {
    value: '',
    label: 'Denumire gest. Nexus',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  profitCenterId: {
    value: '',
    label: 'ID centru profit Nexus',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
};

export const tanksConfigState = {
  id: {
    hidden: true,
    errors: [],
  },
  type: {
    hidden: true,
    errors: [],
  },
  locationId: {
    value: '',
    label: 'Locatie pompa',
    type: 'select',
    options: [],
    validation: requiredNumber,
    errors: [],
    edited: false,
  },
  productName: {
    value: '',
    label: 'Denumire produs',
    type: 'select',
    options: [],
    validation: requiredNumber,
    errors: [],
    edited: false,
  },
  tankName: {
    value: '',
    label: 'Denumire pompa',
    type: 'select',
    options: [],
    validation: requiredNumber,
    errors: [],
    edited: false,
  },
  productId: {
    value: '',
    label: 'ID produs Nexus',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  productCode: {
    value: '',
    label: 'Cod produs Nexus',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  productType: {
    value: '',
    label: 'Tip produs Nexus',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  managementDbAccount: {
    value: '',
    label: 'Cont DB gest.',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  managementCrAccount: {
    value: '',
    label: 'Cont CR gest.',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
};

export const receiptsConfigState = {
  id: {
    hidden: true,
    errors: [],
  },
  type: {
    hidden: true,
    errors: [],
  },
  documentSeries: {
    value: '',
    label: 'Serie document',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
  documentNumber: {
    value: '',
    label: 'Nr. inceput document',
    type: 'input',
    validation: requiredString,
    errors: [],
    edited: false,
  },
};

export const modalInitialState = {
  open: false,
  title: '',
  text: '',
  onSuccess: null,
  onSuccessText: '',
  onClose: null,
  onCloseText: 'Anuleaza',
};
