import React from 'react';
import { useSelector } from 'react-redux';
import { CircularProgress } from '@material-ui/core';
import useStyles from './styles';

const LoadingScreen = () => {
  const styles = useStyles();

  const { loading } = useSelector((state) => state.globalReducer);

  if (!loading) return null;

  return (
    <div className={styles.loadingScreenContainer}>
      <div className={styles.loader}>
        <CircularProgress size={100} color="primary" />
      </div>
    </div>
  );
};

export default LoadingScreen;
