export const FETCH_VEHICLES_SUCCESS = 'VEHICLES::FETCH_VEHICLES_SUCCESS';
export const FETCH_VEHICLES_ERROR = 'VEHICLES::FETCH_VEHICLES_ERROR';

export const FETCH_VEHICLE_DETAILS_SUCCESS =
  'VEHICLES::FETCH_VEHICLE_DETAILS_SUCCESS';
export const FETCH_VEHICLE_DETAILS_ERROR =
  'VEHICLES::FETCH_VEHICLE_DETAILS_ERROR';
export const RESET_VEHICLE_DETAILS = 'VEHICLES::RESET_VEHICLE_DETAILS';

export const SAVE_MERGED_VEHICLE_SUCCESS =
  'VEHICLES::SAVE_MERGED_VEHICLE_SUCCESS';
export const SAVE_MERGED_VEHICLE_ERROR = 'VEHICLES::SAVE_MERGED_VEHICLE_ERROR';

export const SAVE_NORMALIZED_VEHICLE_SUCCESS =
  'VEHICLES::SAVE_NORMALIZED_VEHICLE_SUCCESS';
export const SAVE_NORMALIZED_VEHICLE_ERROR =
  'VEHICLES::SAVE_NORMALIZED_VEHICLE_ERROR';

export const DELETE_MERGED_VEHICLE_SUCCESS =
  'VEHICLES::DELETE_MERGED_VEHICLE_SUCCESS';
export const DELETE_MERGED_VEHICLE_ERROR =
  'VEHICLES::DELETE_MERGED_VEHICLE_ERROR';

export const RESET = 'VEHICLES::RESET';
