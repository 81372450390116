import { createMuiTheme } from '@material-ui/core/styles';

// export default createMuiTheme({
//   palette: {
//     primary: {
//       main: '#f3513c',
//       contrastText: '#eee',
//     },
//     secondary: {
//       main: '#FF0',
//     },
//   },
// });

export default createMuiTheme({});
