import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Box, Grid, Button } from '@material-ui/core';
import {
  DataGrid,
  FormField,
  ValidReceiptsTable,
  InvalidReceiptsTable,
  Modal,
} from '../../components';
import { fetchReceipts, postPushNexusReceipt, resetReceipts } from './actions';
import {
  pushedNexusReceiptsColumns,
  pushedColumns,
  validColumns,
  invalidColumns,
  modalInitialState,
} from './utils';
import useStyles from './styles';
import { DATE_FORMAT, editFilterDate } from '../../utils';

const Receipts = (props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { startDate, endDate } = useSelector((state) => state.globalReducer);
  const { pushedNexusReceipts, receipts } = useSelector(
    (state) => state.receipts
  );

  const [modalData, setModalData] = useState(modalInitialState);
  const [checkBoxes, setCheckBoxes] = useState(0);

  useEffect(() => {
    dispatch(fetchReceipts());
    return () => dispatch(resetReceipts());
  }, [dispatch]);

  const onPostPushNexusReceiptClick = (
    selectedRowsIds,
    groupReceipts,
    uniqueReceipt,
    groupCheck
  ) => {
    setModalData({
      ...modalData,
      open: true,
      title: `${selectedRowsIds.length} alimentari vor fi adaugate in Nexus!`,
      text: groupCheck
        ? `Alimentarile vor fi grupate! Din ${uniqueReceipt.selectedRows.length} alimentari grupate vor rezulta ${uniqueReceipt.uniqueSelectedRows.length} bonuri de consum`
        : 'Alimentarile NU vor fi grupate!',
      onSuccess: () => {
        dispatch(postPushNexusReceipt(selectedRowsIds, groupReceipts));
        setModalData(modalInitialState);
      },
      onSuccessText: 'Da',
      onClose: () => {
        setModalData(modalInitialState);
        setCheckBoxes((prevState) => prevState + 1);
      },
    });
  };

  return (
    <Container className={styles.navbarDisplayFlex}>
      <Grid container justify="space-between" alignItems="center" item xs={12}>
        <Box my={5}>
          <p className={styles.title}>Alimentari si Bonuri de consum</p>
        </Box>
        <Grid container justify="space-evenly" alignItems="center" item xs={6}>
          <FormField
            type="receipts"
            id="startDate1"
            _key="startDate"
            data={startDate}
            maxDate={endDate.value}
            onChange={(date) =>
              dispatch(editFilterDate('startDate', date.format(DATE_FORMAT)))
            }
          />
          <FormField
            type="receipts"
            id="endDate1"
            _key="endDate"
            data={endDate}
            minDate={startDate.value}
            onChange={(date) =>
              dispatch(editFilterDate('endDate', date.format(DATE_FORMAT)))
            }
          />
          <Button
            variant="contained"
            color="primary"
            size="medium"
            type="button"
            disabled={
              startDate.errors.length !== 0 || endDate.errors.length !== 0
            }
            onClick={() => dispatch(fetchReceipts())}
          >
            Cauta
          </Button>
        </Grid>
      </Grid>

      <Grid container spacing={5} className={styles.tables}>
        <Grid item md={12}>
          <p>Bonuri de consum introduse in Nexus</p>
          <DataGrid
            name="pushed"
            columns={pushedNexusReceiptsColumns}
            rows={pushedNexusReceipts}
            options={{ filter: true }}
          />
        </Grid>

        <Grid item md={12}>
          <p>Alimentari introduse in Nexus</p>
          <DataGrid
            name="pushed"
            columns={pushedColumns}
            rows={receipts.pushed}
            options={{ filter: true }}
          />
        </Grid>

        <Grid item md={12}>
          <ValidReceiptsTable
            columns={validColumns}
            rows={receipts.valid}
            postPushNexusReceipt={onPostPushNexusReceiptClick}
            checkBoxes={checkBoxes}
          />
        </Grid>
        <Grid item md={12}>
          <p>Alimentari invalide</p>
          <InvalidReceiptsTable
            columns={invalidColumns}
            rows={receipts.invalid}
          />
        </Grid>
      </Grid>

      <Modal {...modalData} />
    </Container>
  );
};

export default Receipts;
