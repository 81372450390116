const { REACT_APP_PROTOCOL, REACT_APP_BASE_URL } = process.env;

export const getBaseApi = () => `${REACT_APP_PROTOCOL}://${REACT_APP_BASE_URL}`;

export const paths = {
  POST_LOGIN_USER: '/login',
  FETCH_USER_INFO: '/user',
  FETCH_CONFIGS: '/configs',
  POST_TEST_CONFIG_CONNECTION: '/configs/test-connection',
  FETCH_DRIVERS: '/drivers',
  FETCH_DRIVER_DETAILS: '/drivers/details',
  FETCH_VEHICLES: '/vehicles',
  FETCH_VEHICLE_DETAILS: '/vehicles/details',
  FETCH_RECEIPTS: '/receipts',
  POST_PUSH_NEXUS_RECEIPTS: '/receipts/push-nexus',
  FETCH_NORMALIZATION: '/normalization',
  FETCH_DASHBOARD: '/dashboard',
};
