import { makeStyles } from '@material-ui/core';

const styles = (theme) => {
  return makeStyles({
    deleteIcon: {
      cursor: 'pointer',
      color: 'rgb(220, 0, 78)',
    },
    editIcon: {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  })();
};

export default styles;
