import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GET } from '../../utils/http';
import { getBaseApi, paths } from '../../utils/config';
import { setIsAuthenticated } from '../../utils/interceptors';

const AuthHOC = (Component, props) => {
  const dispatch = useDispatch();

  const { isAuthenticated } = useSelector((state) => state.globalReducer);

  useEffect(() => {
    (async () => {
      try {
        if (isAuthenticated === null) {
          const result = await GET(getBaseApi(), paths.FETCH_USER_INFO);
          dispatch(setIsAuthenticated(true, result.data));
        }
      } catch (error) {
        //eslint-disable-next-line
        console.log(error);
        dispatch(setIsAuthenticated(false, null));
      }
    })();
  }, [dispatch, isAuthenticated]);

  if (isAuthenticated) {
    return <Component {...props} />;
  }

  if (isAuthenticated === false) {
    return <Redirect to="/login" />;
  }

  return null;
};

export default AuthHOC;
