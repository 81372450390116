import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { DataGrid, DetailsModal } from '..';

// import useStyles from './styles';

const ValidReceiptsTable = (props) => {
  // const styles = useStyles();
  const { rows, columns } = props;

  const [detailsModalData, setDetailsModalData] = useState({
    open: false,
    data: [],
  });

  const getReceiptErrors = (errors) => {
    const _errors = [];
    errors.forEach((error) => {
      if (error.indexOf('sofer') !== -1) {
        const splitted = error.split('pentru soferul cu numele ');
        const firstPart = splitted[0];
        const lastName = splitted[1].split(' ')[0];
        const _error = (
          <>
            {firstPart}{' '}
            <Link
              to={{
                pathname: '/drivers',
                state: { filterValue: lastName },
              }}
            >
              {splitted[1]}
            </Link>
          </>
        );
        _errors.push(_error);
        return;
      }

      if (error.indexOf('vehicul') !== -1) {
        const splitted = error.split('pentru vehiculul cu numele ');
        const firstPart = splitted[0];
        const vehicleName = splitted[1];
        const _error = (
          <>
            {firstPart}{' '}
            <Link
              to={{
                pathname: '/vehicles',
                state: { filterValue: vehicleName },
              }}
            >
              {splitted[1]}
            </Link>
          </>
        );
        _errors.push(_error);
        return;
      }

      _errors.push(error);
    });

    return _errors;
  };
  const getVisibilityIcon = useCallback(
    (receipt) => (
      <VisibilityIcon
        className="viewIconError"
        onClick={() =>
          setDetailsModalData({
            open: true,
            data: getReceiptErrors(receipt.errors),
          })
        }
      />
    ),
    []
  );

  return (
    <>
      <DataGrid
        name="invalid"
        columns={columns}
        rows={rows.map((r) => ({
          ...r,
          errors: getVisibilityIcon(r),
        }))}
        options={{ colorEmptyCell: '#f44336', filter: true }}
      />
      <DetailsModal
        open={detailsModalData.open}
        details={detailsModalData.data}
        onClose={() => setDetailsModalData(false)}
      />
    </>
  );
};

export default ValidReceiptsTable;
