export const receiptStatsColumns = [
  { key: 'receiptStatus', name: 'Status Alimentari', width: 75 },
  { key: 'productName', name: 'Combustibil', width: 75 },
  { key: 'quantity', name: 'Cantitate Totala (litri)', width: 75 },
];

export const normalizationColumns = [
  { key: 'updatedAt', name: 'Data trimitere', width: 130 },
  { key: 'type', name: 'Tip', width: 75 },
  { key: 'executedBy', name: 'Creata de', width: 75 },
  { key: 'tanks', name: 'Tanks DB', width: 75 },
  { key: 'fleetman', name: 'Fleetman DB', width: 75 },
  { key: 'nexus', name: 'Nexus DB', width: 75 },
  { key: 'normalized', name: 'Normalizati', width: 75 },
  { key: 'newAdded', name: 'Noi adaugati', width: 75 },
  { key: 'total', name: 'Total', width: 75 },
  { key: 'valid', name: 'Valizi', width: 75 },
  { key: 'invalid', name: 'Invalizi', width: 75 },
  { key: 'updated', name: 'Actualizati', width: 75 },
];
