export const normalizationModalMessages = (startDate, endDate) => ({
  drivers: 'Esti sigur ca doresti normalizezi soferii?',
  vehicles: 'Esti sigur ca doresti normalizezi vehiculele?',
  receipts: `Esti sigur ca doresti normalizezi alimentarile ${
    startDate && endDate ? `intre ${startDate} si ${endDate}?` : '?'
  }`,
});

export const modalInitialState = {
  open: false,
  title: '',
  text: '',
  onSuccess: null,
  onSuccessText: '',
  onClose: null,
  onCloseText: 'Anuleaza',
};
