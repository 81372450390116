import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';
import { DataGrid, DetailsModal } from '..';

// import useStyles from './styles';

const UnmergedVehiclesTable = (props) => {
  // const styles = useStyles();
  const { name, columns, rows, selectedRows, options, dataDetails } = props;

  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (Object.keys(dataDetails).length && !modalOpen) {
      setModalOpen(true);
    }
  }, [dataDetails, modalOpen]);

  const onCloseDetailsModal = () => {
    setModalOpen(false);
    props.closeDataDetailsModal();
  };

  return (
    <>
      <DataGrid
        name={name}
        columns={columns}
        rows={rows}
        selectedRows={selectedRows}
        options={options}
      />
      <DetailsModal
        open={modalOpen}
        details={dataDetails}
        onClose={onCloseDetailsModal}
      />
    </>
  );
};

const areEqual = (prevProps, nextProps) =>
  prevProps.name === nextProps.name &&
  isEqual(prevProps.columns, nextProps.columns) &&
  isEqual(prevProps.rows, nextProps.rows) &&
  isEqual(prevProps.selectedRows, nextProps.selectedRows) &&
  prevProps.options.onRowClick.toString() ===
    nextProps.options.onRowClick.toString() &&
  isEqual(prevProps.dataDetails, nextProps.dataDetails) &&
  prevProps.fetchDataDetails.toString() ===
    nextProps.fetchDataDetails.toString() &&
  prevProps.closeDataDetailsModal.toString() ===
    nextProps.closeDataDetailsModal.toString();

export default React.memo(UnmergedVehiclesTable, areEqual);
