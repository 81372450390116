export const RECEIPT_STATUSES = {
  PUSHED: 'pushed',
  VALID: 'valid',
  INVALID: 'invalid',
};

export const validColumns = [
  { key: 'serie_document', name: 'serie_document', width: 75 },
  { key: 'data_document', name: 'data_document', width: 75 },
  { key: 'id_gestiune', name: 'id_gestiune', width: 75 },
  { key: 'den_gestiune', name: 'den_gestiune', width: 75 },
  { key: 'id_centru_profit', name: 'id_centru_profit', width: 75 },
  { key: 'id_primitor', name: 'id_primitor', width: 75 },
  { key: 'den_primitor', name: 'den_primitor', width: 75 },
  { key: 'nume_transport', name: 'nume_transport', width: 75 },
  { key: 'id_produs', name: 'id_produs', width: 75 },
  { key: 'cod_produs', name: 'cod_produs', width: 75 },
  { key: 'den_produs', name: 'den_produs', width: 75 },
  { key: 'tip_produs', name: 'tip_produs', width: 75 },
  { key: 'cantitate', name: 'cantitate', width: 75 },
  { key: 'denumire_sup', name: 'denumire_sup', width: 75 },
  { key: 'driverName', name: 'Nume sofer', width: 75 },
];

export const invalidColumns = [
  { key: 'errors', name: 'Erori', width: 75 },
  ...validColumns,
];

export const pushedColumns = [
  { key: 'id', name: 'Id', width: 75 },
  { key: 'updatedAt', name: 'Data trimitere', width: 130 },
  ...validColumns,
];

export const pushedNexusReceiptsColumns = [
  { key: 'updatedAt', name: 'Data trimitere', width: 130 },
  { key: 'ids', name: 'Id-uri Alim.', width: 75 },
  { key: 'numar_document', name: 'numar_document', width: 75 },
  ...validColumns,
];

export const modalInitialState = {
  open: false,
  title: '',
  text: '',
  onSuccess: null,
  onSuccessText: '',
  onClose: null,
  onCloseText: 'Anuleaza',
};
