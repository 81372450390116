import React, { useEffect, useState, useCallback } from 'react';
import useStyles from './styles';
import {
  Grid,
  TextField,
  Box,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';

const DataGrid = ({
  name,
  columns,
  rows,
  selectedRows,
  options,
  checkBoxes,
}) => {
  const styles = useStyles();

  const [filteredRows, setFilteredRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [filterValue, setFilterValue] = useState(options?.filterValue || '');
  const [typingTimeout, setTypingTimeout] = useState(null);

  const filterRows = useCallback(
    (filterValue) => {
      if (filterValue.length) {
        const _rows = [];
        rows.forEach(
          (row) =>
            Object.keys(row).some(
              (key) =>
                ['string', 'number'].indexOf(typeof row[key]) !== -1 &&
                row[key]
                  .toString()
                  .toLowerCase()
                  .indexOf(filterValue.toLowerCase()) !== -1
            ) && _rows.push(row)
        );
        setFilteredRows(_rows);
        return;
      }
      setFilteredRows(rows);
    },
    [rows]
  );

  useEffect(() => {
    checkBoxes > 0 && setSelectAll(false);
  }, [checkBoxes]);

  useEffect(() => {
    setFilteredRows(rows);
    if (options?.filterValue) {
      filterRows(options.filterValue);
    }
  }, [rows, options?.filterValue, filterRows]);

  useEffect(() => {
    if (options?.filterValue) {
      filterRows(options.filterValue);
    }
  }, [options?.filterValue, filterRows]);

  const computeCellStyle = (column, value) => {
    let style = {};

    if (options?.colorEmptyCell && !value) {
      style.backgroundColor = options.colorEmptyCell;
    }
    return (
      <td style={style} className={styles.td} key={column.key}>
        {value}
      </td>
    );
  };

  const computeRowStyle = (row) => {
    let backgroundColor = 'white';
    let cursor = 'pointer';
    // let borderBottom = '1px solid rgba(224, 224, 224, 1)';

    if (selectedRows?.some((sr) => sr?.id === row.id)) {
      backgroundColor = '#acf0bd';
      cursor = 'pointer';
    } else if (row.mergeId) {
      backgroundColor = 'lightgrey';
      cursor = 'auto';
    } else if (!options?.onRowClick) {
      backgroundColor = 'white';
      cursor = 'auto';
    }

    return {
      backgroundColor,
      cursor,
    };
  };

  const onFilteredValueChange = (evt) => {
    setFilterValue(evt.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(setTimeout(() => filterRows(evt.target.value), 500));
    setSelectAll(false);
  };

  const onSelectAllClick = (evt) => {
    setSelectAll(evt.target.checked);
    options?.onSelectAllClick &&
      options?.onSelectAllClick(name, filteredRows, evt.target.checked);
  };

  const useRows = options?.filter ? filteredRows : rows;

  return (
    <>
      <Box my={1}>
        <Grid container alignItems="center">
          {options?.filter && (
            <TextField
              placeholder="Cauta"
              value={filterValue}
              onChange={onFilteredValueChange}
            />
          )}
          {options?.selectAll && (
            <Box mx={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectAll}
                    onChange={onSelectAllClick}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Selecteaza toate alimentarile"
              />
            </Box>
          )}
        </Grid>
      </Box>
      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map((column) => (
                <th
                  width={column.width || 100}
                  className={styles.th}
                  key={column.key}
                >
                  {column.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {useRows.map((row) => (
              <tr
                style={computeRowStyle(row)}
                onClick={() =>
                  options?.onRowClick && options?.onRowClick(name, row)
                }
                key={row.id}
              >
                {columns.map((column) =>
                  computeCellStyle(column, row[column.key])
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DataGrid;
