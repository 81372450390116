import { combineReducers } from 'redux';

import globalReducer from './globalReducer';
import configsReducer from '../modules/configs/reducer';
import driversReducer from '../modules/drivers/reducer';
import vehiclesReducer from '../modules/vehicles/reducer';
import receiptsReducer from '../modules/receipts/reducer';
import normalizationReducer from '../modules/normalization/reducer';
import dashboardReducer from '../modules/dashboard/reducer';

const rootReducer = combineReducers({
  globalReducer,
  configs: configsReducer,
  drivers: driversReducer,
  vehicles: vehiclesReducer,
  receipts: receiptsReducer,
  normalization: normalizationReducer,
  dashboard: dashboardReducer,
});
export default rootReducer;
