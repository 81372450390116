import { makeStyles } from '@material-ui/core';

export default makeStyles({
  navbarDisplayFlex: {
    height: '80vh',
  },
  errorMessage: {
    fontSize: '25px',
    color: '#f44336',
  },
  title: {
    fontSize: '25px',
  },
  tabsContainer: {
    flexGrow: 1,
  },
  deleteIcon: {
    cursor: 'pointer',
    color: 'rgb(220, 0, 78)',
  },
});
