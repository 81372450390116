import moment from 'moment';
import { DATE_FORMAT } from '.';

export const requiredString = (value) => {
  if (!value.length) return ['Camp Obligatoriu'];
  return [];
};

export const requiredNumber = (value) => {
  if (!value) return ['Camp Obligatoriu'];
  return [];
};

export const requiredArray = (value) => {
  if (!value.length) return ['Camp Obligatoriu'];
  return [];
};

export const requiredDate = (value) => {
  if (value === null) return ['Camp Obligatoriu'];
  return [];
};

export const isNumber = (value) => {
  if (!new RegExp(/^-?\d+$/).test(value)) {
    return ['Numar invalid'];
  }
  return [];
};

export const isFloatNumber = (value) => {
  if (!new RegExp(/^(-|[0-9]+|[0-9]+,[0-9]+)$/).test(value)) {
    return ['Numar invalid'];
  }
  return [];
};

export const dateDifferenceDays =
  (currentField, complementaryField, diffDays) => (state, value) => {
    state[complementaryField].errors = [];
    const errors = [];
    const days = Math.abs(
      moment.duration(
        moment(value, DATE_FORMAT).diff(
          moment(state[complementaryField].value, DATE_FORMAT),
          'days'
        )
      )
    );

    if (days > diffDays) {
      state[complementaryField].errors.push(
        `Diferenta intre date este > de ${diffDays} zile`
      );

      errors.push(`Diferenta intre date este > de ${diffDays} zile`);
    }
    return errors;
  };
