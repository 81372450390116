export const FETCH_DRIVERS_SUCCESS = 'DRIVERS::FETCH_DRIVERS_SUCCESS';
export const FETCH_DRIVERS_ERROR = 'DRIVERS::FETCH_DRIVERS_ERROR';

export const FETCH_DRIVER_DETAILS_SUCCESS =
  'DRIVERS::FETCH_DRIVER_DETAILS_SUCCESS';
export const FETCH_DRIVER_DETAILS_ERROR = 'DRIVERS::FETCH_DRIVER_DETAILS_ERROR';
export const RESET_DRIVER_DETAILS = 'DRIVERS::RESET_DRIVER_DETAILS';

export const SAVE_MERGED_DRIVER_SUCCESS = 'DRIVERS::SAVE_MERGED_DRIVER_SUCCESS';
export const SAVE_MERGED_DRIVER_ERROR = 'DRIVERS::SAVE_MERGED_DRIVER_ERROR';

export const SAVE_NORMALIZED_DRIVER_SUCCESS =
  'DRIVERS::SAVE_NORMALIZED_DRIVER_SUCCESS';
export const SAVE_NORMALIZED_DRIVER_ERROR =
  'DRIVERS::SAVE_NORMALIZED_DRIVER_ERROR';

export const DELETE_MERGED_DRIVER_SUCCESS =
  'DRIVERS::DELETE_MERGED_DRIVER_SUCCESS';
export const DELETE_MERGED_DRIVER_ERROR = 'DRIVERS::DELETE_MERGED_DRIVER_ERROR';

export const RESET = 'DRIVERS::RESET';
